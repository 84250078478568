import styled from "styled-components";

export const AddContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  margin: 0 auto;

  .client_dropdown {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-select-enabled {
    margin-bottom: 0;
  }
`;

export const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #000;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const MetaWrapper = styled.div`
  display: flex;
`;

export const UploadWrapper = styled.div`
  width: 40%;
  margin: 0 auto;
`;
