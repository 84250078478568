import React from "react";
import {
  Button,
  Typography,
  Affix,
  notification,
  Tooltip,
  Modal,
  Icon,
  Menu,
  Dropdown,
  Upload,
} from "antd";
import { Switch, Route, withRouter } from "react-router-dom";
import { logoutRequest } from "../Login/LoginActions";
import { history } from "../../history";
import { HeaderContainerWrapper } from "./SeachContainerStyle";
import TabContainer from "./Tabs/TabContainer";
import ReportContainer from "../Report/ReportContainer";
import {
  publishReport,
  deleteReport,
  uploadPDF,
  uploadTOC,
} from "./Tabs/Add/AddActions";
import { signOut } from "../Login/MsLoginConfig";

const { Title } = Typography;
const confirm = Modal.confirm;

const props = {
  action: null,
  onChange({ file, fileList }) {
    if (file.status !== "uploading") {
      uploadPDF(file);
    }
  },
};

class Search extends React.Component {
  state = {
    title: "",
    publish_boolean: false,
    down_icon: true,
    reload_toc: false,
    isHomeDisabled: false
  };

  handleUploadPDF = (file) => {
    let report = this.props.location.pathname;
    let report_id = report.split("/")[2] || null;
    let version = report.split("/")[3] || null;
    uploadPDF(file, report_id, version, (err, res) => {
      if (err) {
        notification.error({
          message: "Error",
          description: "Failed to upload the PDF",
        });
      } else {
        notification.success({
          message: "Success",
          description: "Succeded in uploading the PDF",
        });
      }
    });
  };

  handleUploadTOC = (file) => {
    let report = this.props.location.pathname;
    let report_id = report.split("/")[2] || null;
    let version = report.split("/")[3] || null;
    let forecast_period = report.split("/")[4] || null;
    uploadTOC(file, report_id, version, forecast_period, (err, res) => {
      if (err) {
        notification.error({
          message: "Error",
          description: "Failed to upload the TOC",
        });
      } else {
        notification.success({
          message: "Success",
          description: "Succeeded in uploading the TOC",
        });
        this.handleReloadTOC(true);
      }
    });
  };

  handleReloadTOC = (value) => {
    this.setState({
      reload_toc: value,
    });
  };

  handleDownIcon = (value) => {
    this.setState({
      down_icon: value,
    });
  };

  componentDidMount = () => {
    if (
      localStorage.getItem("token") == "null" ||
      !localStorage.getItem("token")
    ) {
      history.push("/login");
    }
  };

  handleTitleChange = (newTitle) => {
    let report = this.props.location.pathname;
    let version = report.split("/")[3] || null;
    newTitle = version ? newTitle + " v" + version : newTitle;
    this.setState({
      ...this.state,
      title: newTitle,
    });
  };

  triggerPublishButton = (newTitle) => {
    let report = this.props.location.pathname;
    let version = report.split("/")[3] || null;
    newTitle = version ? newTitle + " v" + version : newTitle;
    this.setState({
      title: newTitle,
      publish_boolean: true,
    });
  };

  showDeleteConfirm = () => {
    confirm({
      title: "Are you sure delete this report?",
      content: "This is an irreversible process",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        this.handleDelete();
      },
      onCancel() {},
    });
  };

  handleHome = () => {
    this.setState({
      title: "",
      publish_boolean: false,
    });
    history.push("/");
  };

  handleDisableHomeToggle = () => {
    this.setState({
      isHomeDisabled: !this.state.isHomeDisabled
    });
    history.push("/");
  };

  handleLogout = async () => {
    try {
      await logoutRequest(localStorage.getItem("token"));
      localStorage.setItem("token", null);
      signOut();
      // history.push('/login');
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Error",
        description: "Error Logging out",
      });
    }
  };

  handlePublish = async () => {
    try {
      let report = this.props.location.pathname;
      let report_id = report.split("/")[2] || null;
      let version = report.split("/")[3] || null;
      let response = await publishReport(report_id, version);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Succesfully published report",
        });
      } else {
        notification["error"]({
          message: "Error",
          description:
            response.data && response.data.message
              ? response.data.message
              : "Error publishing report",
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Error",
        description:
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Error publishing report",
      });
    }
  };

  handleDelete = async () => {
    try {
      let report = this.props.location.pathname;
      let report_id = report.split("/")[2] || null;
      let version = report.split("/")[3] || null;
      let response = await deleteReport(report_id, version);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Succesfully deleted report",
        });
        this.handleHome();
        history.push("/");
      } else {
        notification["error"]({
          message: "Error",
          description:
            response.data && response.data.message
              ? response.data.message
              : "Error deleting report",
        });
      }
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Error",
        description: "Error deleting report",
      });
    }
  };

  render() {
    const menu = (
      <Menu>
        <Menu.Item onClick={() => this.handlePublish()}>
          <Icon type="save" /> Publish
        </Menu.Item>
        <Menu.Item onClick={() => this.showDeleteConfirm()}>
          <Icon type="delete" /> Delete
        </Menu.Item>
        <Menu.Item>
          <Upload
            {...props}
            accept={".pdf"}
            fileList={[]}
            customRequest={(file, fileList) => {
              this.handleUploadPDF(file);
            }}
          >
            <Icon type="upload" />{" "}
            <span style={{ marginLeft: 7 }}>Upload PDF</span>
          </Upload>
        </Menu.Item>
        <Menu.Item>
          <Upload
            {...props}
            accept={".csv"}
            fileList={[]}
            customRequest={(file, fileList) => {
              this.handleUploadTOC(file);
            }}
          >
            <Icon type="upload" />{" "}
            <span style={{ marginLeft: 7 }}>Upload TOC</span>
          </Upload>
        </Menu.Item>
      </Menu>
    );

    const action_dropdown = this.state.publish_boolean ? (
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button
          type="primary"
          className="right-button"
          onFocus={() => {
            this.handleDownIcon(!this.state.down_icon);
          }}
          onBlur={() => {
            this.handleDownIcon(!this.state.down_icon);
          }}
        >
          <Icon
            style={{
              fontSize: 12,
              transform: `rotate(${this.state.down_icon ? "0deg" : "180deg"})`,
              transition: "300ms ease all",
            }}
            type="down"
          />
          Actions
        </Button>
      </Dropdown>
    ) : (
      ""
    );

    return (
      <div>
        <Affix>
          <HeaderContainerWrapper>
            <Button
              disabled={this.state.isHomeDisabled}
              type="primary"
              className="left-button"
              onClick={() => {
                this.handleHome();
              }}
            >
              <Icon type="home" />
              Home
            </Button>
            <Tooltip title={this.state.title}>
              <Title level={4} ellipsis className="title">
                {this.state.title}
              </Title>
            </Tooltip>
            <div>
              {action_dropdown}
              <Button
                type="warning"
                className="right-button"
                onClick={() => {
                  this.handleLogout();
                }}
              >
                <Icon type="logout" />
                Logout
              </Button>
            </div>
          </HeaderContainerWrapper>
        </Affix>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <TabContainer
                handleTitleChange={this.handleTitleChange}
                triggerPublishButton={this.triggerPublishButton}
                handleHome={this.handleHome}
                handleDisableHomeToggle={this.handleDisableHomeToggle}
                title={this.state.title}
              />
            )}
          />
          <Route
            path="/reports/:report_id/:version/:forecast_period"
            render={() => (
              <ReportContainer
                triggerPublishButton={this.triggerPublishButton}
                reload_toc={this.state.reload_toc}
                handleReloadTOC={this.handleReloadTOC}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(Search);
