import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import TocContainer from './Toc/TocContainer';
import ContentContainer from './Content/ContentContainer';
import { getReportDetails, deleteSection } from './ReportActions';

import { ReportWrapper } from './ReportContainer.style';

class ReportContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            section_id: '',
            location: '',
            prev_location: '',
            move_flag: false,
            menu_select: false
        }
    }

    componentDidMount = () => {
        getReportDetails(localStorage.getItem('token'), 
        this.props.match.params.report_id, 
        this.props.match.params.version,
        this.props.match.params.forecast_period, 
        (data) => {
            this.props.triggerPublishButton(data.title);
        });
    }

    setMoveFlag = (value) => {
        this.setState({
            ...this.state,
            move_flag: value
        })
    }

    setMenuSelect = (value) => {
        this.setState({
            ...this.state,
            menu_select: value
        }, () => {
            this.setState({
                ...this.state,
                menu_select: value
            })
        })
    }

    handleDeleteSection = () => {
        let id = this.props.match.params.report_id;
        let version = this.props.match.params.version;
        let section_id = this.state.section_id;
        let token = localStorage.getItem('token');
        deleteSection(token, id, version, section_id, () => {
            this.props.handleReloadTOC(true);
        })
    }

    setDetails = (title, location, section_id, set_move_flag, menu_select_flag) => {
        this.setState(state => {
            return {
                ...this.state,
                title: title,
                location: location,
                section_id: section_id,
                move_flag: set_move_flag ? false : state.move_flag,
                menu_select: menu_select_flag
            }
        });
    }

    setPrevLocation = (location) => {
        this.setState({
            ...this.state,
            prev_location: location
        });
    }

    render() {
        return (
            <ReportWrapper>
                <TocContainer
                    set_details={this.setDetails}
                    prev_location={this.state.prev_location}
                    setMenuSelect={this.setMenuSelect}
                    toc_location={this.state.location}
                    set_prev_location={this.setPrevLocation}
                    setMoveFlag={this.setMoveFlag}
                    move_flag={this.state.move_flag}
                    reload_toc={this.props.reload_toc}
                    handleReloadTOC={this.props.handleReloadTOC}
                    handleDeleteSection={this.handleDeleteSection}
                />
                <ContentContainer 
                    title={this.state.title} 
                    section_id={this.state.section_id} 
                    setMenuSelect={this.setMenuSelect} 
                    menu_select={this.state.menu_select}
                    toc_location={this.state.location} 
                    set_prev_location={this.setPrevLocation} 
                    setMoveFlag={this.setMoveFlag} 
                    move_flag={this.state.move_flag}
                    handleDeleteSection={this.handleDeleteSection} />
            </ReportWrapper>
        );
    }
}

export default withRouter(ReportContainer);