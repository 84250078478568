import React from "react";
import {
  Select,
  notification,
  Typography,
  Steps,
  Dropdown,
  Button,
  Icon,
  Menu,
  message,
} from "antd";
import { fetchReports, getReportFactsetPublished, getReportNonFactsetPublished, getReportSandPPublished } from "./AddActions";
import {
  AddContainerWrapper,
  DetailsWrapper,
  FormWrapper,
  MetaWrapper,
  UploadWrapper,
} from "./AddContainerStyle";
import config from "../../../../config/config";
import MetaFormComponent from "./MetaForm/MetaFormContainer";
import TOCUploadComponent from "./TOCUpload/TOCUploadFormContainer";
import Factset from "./Factset/Factset";
import SandP from "./SandP/SandP";
import PDFUploadComponent from "./PDFUpload/PDFUploadFormContainer";

const { Paragraph, Text } = Typography;

const Option = Select.Option;
const Step = Steps.Step;

let options = {
  global: [],
  country: [],
  region: [],
  factset_country: [],
  factset_region: [],
};

const steps = [
  {
    key: 0,
    title: "Meta Details",
  },
  {
    key: 1,
    title: "TOC Upload",
    title2: "PDF Upload",
  },
];

function debounceSearch(func, wait) {
  let timeout;
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}


async function handleFetchReports(value, callback) {
  try {
    let Reports = await fetchReports(value);
    callback(Reports.data);
  } catch (error) {
    console.log(error);
    notification["error"]({
      message: "Error",
      description: "Error fetching reports",
    });
    callback([]);
  }
}

class AddReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      value: undefined,
      current_report: null,
      chosen_options: [],
      meta_body: null,
      current_step: 0,
      fileList: [],
      factset: false, // factset
      sandp: false,   // s&p client
      // isDropdownDisable: false,
      factset_meta_body: null, // both sandp and factset meta body are stored in this state
      clientType: 'Non FactSet', // "Non Factset" || "FactSet" | "S&P"
      forecast_period: null
    };

    this.handleSearch = debounceSearch(this.handleSearch.bind(this), 500);
  }

  componentDidMount() {
    for (let key in options) {
      options[key] = config[key].map((elem) => {
        return <Option key={elem}>{elem}</Option>;
      });
    }
  }

  handleSearch = (value) => {
    if (value.toLowerCase().startsWith("sandp")) {
      this.setState({
        sandp: true,
        factset: false,
        clientType: "S&P"
      });
    } else if (value.toLowerCase().startsWith("factset")) {
      this.setState({
        sandp: false,
        factset: true,
        clientType: "FactSet"
      });
    } 
    // else {
    //   // Reset state for any other value
    //   this.setState({
    //     sandp: false,
    //     factset: false,
    //     clientType: "Non FactSet"
    //   });
    // }
    // add type of report before query
    // const clientType = this.state.clientType.toLowerCase();
    // const prefix = (clientType === "s&p" || clientType === "factset") ? "factset" : "";
    // const res = `${prefix} ${value}`;

    // if value is not empty then fetch reports
    value !== "" && handleFetchReports(value, (data) => this.setState({ data }));
  };

  handleMetaAddition = (meta_body) => {
    this.setState({
      ...this.state,
      forecast_period: meta_body.forecast_period,
      meta_body: meta_body,
    }, () => {
      // Callback function to execute handleNextStep after state is updated
      this.handleNextStep();
    });
  };

  handleFactsetMetaAddition = (factset_meta_body) => {
    this.setState({
      ...this.state,
      forecast_period: factset_meta_body.forecast_period,
      factset_meta_body: factset_meta_body,
    }, () => {
      // Callback function to execute handleNextStep after state is updated
      this.handleNextStep();
    });
  };

  isReportPresent = async (report_id, forecast_period) => {
    let reports = this.state.factset ? await getReportFactsetPublished(report_id, forecast_period)
    : this.state.sandp ? await getReportSandPPublished(report_id, forecast_period)
    : await getReportNonFactsetPublished(report_id, forecast_period);

    if(reports.status === 404) { // err.respsone (report doesn't exists)
      return false;
    }
    let isPresent = false; 

    if(reports && reports.data) {
      if(reports.data.isFactsetPublished) { // factset
        isPresent = reports.data.isFactsetPublished;
      } else  if (reports.data.isSandpPublished) { // s and p
        isPresent = reports.data.isSandpPublished;
      } else if (reports.data.isNonFactsetPublished === true) { // non factset (not null or undefined)
        isPresent = true;
      }
    }
    
    return isPresent;
  };

  handleChange = (value) => {
    if (value) {
      let report = this.findReport(parseInt(value)); // if report doesn't have "Show in digitizer" tag, then return valued is {}

      this.setState({
        ...this.state,
        current_step: 0,
        fileList: [],
        chosen_options: [],
        current_report: report,
      });

      if (report && report.title) {
        this.props.handleTitleChange(report.title);
        this.props.handleDisableHomeToggle();
      }
    }

    this.setState({ value: value });
  };

  handleGeographyChange = (value) => {
    this.setState({
      ...this.state,
      chosen_options: options[value],
    });
  };

  findReport = (id) => {
    for (let elem of this.state.data) {
      if (elem.id === id) {
        if (
          elem.related_tags &&
          elem.related_tags.length &&
          elem.related_tags.indexOf("Show in digitizer") !== -1
        )
          return elem;
        else {
          alert(
            "This report does not have a 'Show in digitizer' tag yet. Please ask an admin to add the best seller tag to the report on CMS if this needs to be digitized."
          );
          return {};
        }
      }
    }
    return null;
  };

  handleNextStep = async () => {
    if (await this.isReportPresent(this.state.value, this.state.forecast_period)) {
      notification.warning({
        message: "Warning",
        description: "This Report is already Present"
      });
    }

    this.setState({ current_step: this.state.current_step + 1 });
  };

  validateUploadConditions(fileList) {
    const maxFiles = this.state.sandp ? 2 : 1;
    if (fileList.length > maxFiles) {
      notification["error"]({
        message: "Error",
        description: `Cannot upload more than ${maxFiles} file${maxFiles > 1 ? 's' : ''}`,
      });
      return false;
    }
    return true;
  }

  handleUpload = (file, fileList) => {
    // Validate file upload constraints
    if (!this.validateUploadConditions(fileList)) {
      return false;
    }

    let correctSandP = false;

    if (this.state.sandp) {
      const part1Regex = /report part 1/i;
      const part2Regex = /report part 2/i;
      const foundPart1 = fileList.some(file => part1Regex.test(file.name));
      const foundPart2 = fileList.some(file => part2Regex.test(file.name));

      correctSandP = foundPart1 && foundPart2;

      if (!correctSandP) {
        notification["error"]({
          message: "Error",
          description: <p>Filename is invalid. Please provide <strong>two PDF files</strong> with keyword <strong>'Report Part 1'</strong> and <strong>'Report Part 2'</strong> in the file name.</p>,
        });
        return false;
      }
    }

    // Update the state with the new file list
    this.setState({ fileList });

    return true;
  };

  enableFactsetCheckboxAfterSubmit = (value) => {
    this.setState({
      // isDropdownDisable: value,
      factset: value,
    });
  };

  handleClientMenuClick = (e) => {
    let factset = false, sandp = false;

    if (e.key === "FactSet") {
      factset = true;
    } else if (e.key === "S&P") {
      sandp = true;
    }

    this.setState({
      clientType: e.key,
      factset: factset,
      sandp: sandp,
    });

    message.success(`${e.key} selected`);
  }

  // reset all the states
  handleResetState = () => {
    this.setState({
      data: [],
      value: undefined,
      current_report: null,
      chosen_options: [],
      meta_body: null,
      current_step: 0,
      fileList: [],
      factset: false,
      sandp: false,
      factset_meta_body: null,
      clientType: 'Non FactSet',
      forecast_period: null
    });
    this.props.handleDisableHomeToggle();
    this.props.handleHome();
  }

  render() {
    let new_form_data;
    let region, country, global;

    if (
      this.state.factset_meta_body &&
      this.state.factset_meta_body.geography
    ) {
      if (
        Object.keys(this.state.factset_meta_body.geography)[0] ===
        "factset_region"
      ) {
        region = {
          region: this.state.factset_meta_body.geography["factset_region"],
        };
      } else if (
        Object.keys(this.state.factset_meta_body.geography)[0] ===
        "factset_country"
      ) {
        country = {
          country: this.state.factset_meta_body.geography["factset_country"],
        };
      } else {
        global = { global: "Global" };
      }
    }

    this.state.current_report &&
      this.state.factset_meta_body &&
      (new_form_data = {
        id: this.state.current_report.id,
        sub_category_id: this.state.current_report.sub_category_id,
        category_id: this.state.current_report.category_id,
        title: this.state.current_report.title,
        geography: this.state.factset_meta_body.geography.factset_region
          ? region
          : this.state.factset_meta_body.geography.factset_country
            ? country
            : global,
        personId: this.state.factset_meta_body.personId,
        givenName: this.state.factset_meta_body.givenName,
        displayName: this.state.factset_meta_body.displayName,
        countryCodes: this.state.factset_meta_body.countryCodes,
        publishDate: this.state.factset_meta_body.publishDate,
        forecast_period: this.state.factset_meta_body.forecast_period,
      });

    const options =
      this.state.data && this.state.data.length
        ? this.state.data.map((elem) => (
          <Option key={elem.id}>{elem.title}</Option>
        ))
        : [];

    const stepper_content = [
      <MetaWrapper>
        {this.state.factset && (
          <Factset
            chosen_options={this.state.chosen_options}
            handleGeographyChange={this.handleGeographyChange}
            handleFactsetMetaAddition={this.handleFactsetMetaAddition}
            current_report={this.state.current_report}
          />
        )}

        {this.state.sandp && (
          <SandP
            chosen_options={this.state.chosen_options}
            handleGeographyChange={this.handleGeographyChange}
            handleFactsetMetaAddition={this.handleFactsetMetaAddition}
            current_report={this.state.current_report}
          />
        )}

        {!this.state.factset && !this.state.sandp && (
          <MetaFormComponent
            chosen_options={this.state.chosen_options}
            handleGeographyChange={this.handleGeographyChange}
            current_report={this.state.current_report}
            title={this.props.title}
            handleMetaAddition={this.handleMetaAddition}
          />
        )}
      </MetaWrapper>
      ,
      <div>
        {this.state.factset && (
          <PDFUploadComponent
            triggerPublishButton={this.props.triggerPublishButton}
            fileList={this.state.fileList}
            handleUpload={this.handleUpload}
            handlePreviousStep={this.handlePreviousStep}
            new_form_data={new_form_data}
            enableFactsetCheckboxAfterSubmit={
              this.enableFactsetCheckboxAfterSubmit
            }
            clientType={this.state.clientType}
            handleResetState={this.handleResetState}
          />
        )}

        {this.state.sandp && (
          <PDFUploadComponent
            triggerPublishButton={this.props.triggerPublishButton}
            fileList={this.state.fileList}
            handleUpload={this.handleUpload}
            handlePreviousStep={this.handlePreviousStep}
            new_form_data={new_form_data}
            enableFactsetCheckboxAfterSubmit={
              this.enableFactsetCheckboxAfterSubmit
            }
            clientType={this.state.clientType}
            handleResetState={this.handleResetState}
          />
        )}

        {!this.state.factset && !this.state.sandp && (
          <UploadWrapper>
            <TOCUploadComponent
              triggerPublishButton={this.props.triggerPublishButton}
              fileList={this.state.fileList}
              handleUpload={this.handleUpload}
              handlePreviousStep={this.handlePreviousStep}
              meta_body={this.state.meta_body}
              handleResetState={this.handleResetState}
            />
          </UploadWrapper>
        )}
      </div>
      ,
    ];

    const details_pane = this.props.title ? (
      <div style={{ marginTop: "20px", marginLeft: "10px" }}>
        <Paragraph ellipsis>
          {" "}
          <Text type="secondary" strong>
            Website Title :{" "}
          </Text>{" "}
          {this.state.current_report ? this.state.current_report.title : ""}
        </Paragraph>
        <DetailsWrapper>
          <Paragraph ellipsis>
            <Text type="secondary" strong>
              Category :
            </Text>
            {this.state.current_report
              ? this.state.current_report.category_name
              : ""}
          </Paragraph>
          <Paragraph ellipsis>
            {" "}
            <Text type="secondary" strong>
              Subcategory :
            </Text>
            {this.state.current_report
              ? this.state.current_report.sub_category_name
              : ""}
          </Paragraph>
        </DetailsWrapper>

        <FormWrapper>
          <Steps current={this.state.current_step}>
            {steps.map((item) => (
              <Step
                key={item.key}
                title={
                  (this.state.factset || this.state.sandp) && item.title === "TOC Upload"
                    ? item.title2
                    : item.title
                }
              />
            ))}
          </Steps>
        </FormWrapper>

        <div>{stepper_content[this.state.current_step]}</div>
      </div>
    ) : (
      ""
    );

    const items = (
      <Menu onClick={this.handleClientMenuClick}>
        <Menu.Item key="Non FactSet">
          Non FactSet
        </Menu.Item>
        <Menu.Item key="FactSet">
          FactSet
        </Menu.Item>
        <Menu.Item key="S&P">
          S&P
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <AddContainerWrapper>
          <Select
            showSearch
            value={this.props.title ? this.state.value : undefined}
            placeholder={"Search for a report by it's title"}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            allowClear
            onChange={this.handleChange}
            onSearch={this.handleSearch}
          >
            {options}
          </Select>

          <Dropdown disabled={this.props.title !== ""} overlay={items} className="client_dropdown">
            <Button style={{ fontSize: "18px", fontWeight: "500" }}>
              {this.state.clientType} <Icon type="down" />
            </Button>
          </Dropdown>

          {this.props.title && (
            <Button 
              onClick={() => {
                this.handleResetState()
              }}
              type="primary"
              style={{ marginLeft: "10px", fontSize: "18px", fontWeight: "500" }}>
              RESET
            </Button>
          )}

        </AddContainerWrapper>

        {details_pane}
      </div>
    );
  }
}

export default AddReport;
