import styled from 'styled-components';

export const ContentHeaderWrapper = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    border-bottom: 1px solid #eee;
    .ant-btn {
        margin-right: 15px;
    }
`;