import styled from "styled-components";

export const SandPWrapper = styled.div`
  .factset_form_container {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    column-gap : 80px;

    .factset_field_value {
      margin-left: 30px;
    }
  }

  .factset_field {
    display: flex;
    justify-content : space-around;
  }

  .factset_field_publish {
    display: flex;
    justify-content : flex-start;
    gap: 12px;
  }
`;
