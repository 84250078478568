import styled from "styled-components";

export const HeaderContainerWrapper = styled.div`
  font-size: 16px;
  box-shadow: 0px 0px 10px 0px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .title {
    margin-top: 15px;
    justify-content: center;
    margin-right: 25px;
    max-width: 725px;
  }

  .left-button {
    margin: 15px 25px 15px 30px;
  }

  .right-button {
    margin: 15px 30px 15px 0px;
  }
`;
