import axios from 'axios';
import config from '../../config/config';

import { notification } from 'antd';

export const getReportDetails = (token, id, version, forecast_period, callback) => {

    axios.get(
        config.api.base_url + config.api.report_details 
        + '?id=' + id 
        + '&version=' + version 
        + '&forecast_period=' + forecast_period, 
        {headers: {authorization: token}})
    .then(response => {
        callback(response.data);
    }).catch(error => {
        console.error(error);
        notification.error({
            message: 'Error!',
            description: 'Unable to fetch report details.'
        })
    });
}

export const deleteSection = (token, id, version, section_id, callback) => {
    axios.get(config.api.base_url + config.api.delete_section + '?id='
    + id + '&version=' + version + '&section_id=' + section_id, {headers: {authorization: token}})
    .then(response => {
        notification.success({
            message: 'Success',
            description: response.data && response.data.message ? response.data.message : 'Successfully deleted the section and all its subsections!'
        })
        callback();
    }).catch(error => {
        notification.error({
            message: 'Error!',
            description: 'Something went wrong. Please contact your admin!'
        });
    });
}