import axios from 'axios';
import config from '../../config/config';

export const loginRequest = (token) => {
    return axios.post(config.api.base_url + '/login', {access_token: token});
}

export const logoutRequest = (token) => {
    let headers = {'authorization' : token}
    return axios.get(config.api.base_url + '/logout', {headers: headers});
}