import React from "react";
import { Form, Upload, Icon, Button, notification } from "antd";
import { history } from "../../../../../history";
import { TOCUpload } from "./TOCUploadFormActions";
import { addMeta } from "../MetaForm/MetaFormActions";

const Dragger = Upload.Dragger;

class TOCUploadComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiAttempt: false,
    };
  }

  setApiAttempt = (value) => {
    this.setState({
      apiAttempt: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setApiAttempt(true);
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let form_data = new FormData();
        form_data.append("attachment", values.files.fileList[0].originFileObj);
        try {
          let response = await addMeta(this.props.meta_body);
          let report_data = response.data;
          await TOCUpload(
            form_data,
            report_data.content.id,
            report_data.content.version,
            this.props.meta_body.forecast_period,
          );
          notification["success"]({
            message: "Success",
            description: "Succeeded in adding report",
          });
          this.setApiAttempt(false);
          this.props.triggerPublishButton(report_data.content.title);
          this.props.handleResetState();
          history.push(
            "/reports/" +
              report_data.content.id +
              "/" +
              report_data.content.version +
              "/" +
              report_data.content.forecast_period
          );
        } catch (error) {
          this.setApiAttempt(false);
          console.log(error);
          notification["error"]({
            message: "Error",
            description: "Error adding report",
          });
        }
      }
    });
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item>
          {this.props.form.getFieldDecorator("files", {
            rules: [{ required: true, message: "Please upload a TOC!" }],
          })(
            <Dragger
              openFileDialogOnClick
              customRequest={() => {}}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              beforeUpload={this.props.handleUpload}
              fileList={this.props.fileList}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          )}
        </Form.Item>
        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            disabled={this.state.apiAttempt}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: "meta_form" })(TOCUploadComponent);
