import React from 'react';
import { notification, Button } from 'antd';
import config from '../../config/config';
import GoogleLogin from 'react-google-login';
import { history } from '../../history';
import LoginWrapper from './LoginContainerStyle';
import google_logo from '../../img/google_logo.png';
import send_icon from '../../img/send.png';
import { loginRequest } from "./LoginActions";
import { signIn } from './MsLoginConfig';

const AuthComponent = () => {

    let handleLoginClick = () => {
        document.querySelector('.hidden-login-btn').click();
    }

    let handleSuccess = async (token) => {
        signIn();
    }

    return(
    <LoginWrapper>
        <div className="g-sign-in-wrapper">
        <div className="left-half">
            <div className="intro-text">
            <h3>390k</h3>
            <h3>Prototype</h3>
            </div>
        </div>
        <div className="right-half">
            {/* <GoogleLogin
            clientId={config.google_client_id}
            className="hidden-login-btn"
            buttonText="Login"
            onSuccess={handleSuccess}
            onFailure={handleFailure}
            />
            <div id="customBtn" className="customGPlusSignIn" onClick={() => handleLoginClick()}>
            <span className="icon">
                <p className="optional-text">Sign In</p>
                <img src={google_logo} alt="Google-Logo" />
            </span>
            <span className="buttonText">Login with Google</span>
            <span className="send-icon">
                <img src={send_icon} alt="Send-Icon" />
            </span>
            </div> */}
            <Button className="customGPlusSignIn" id="SignIn" type="primary" onClick={() => handleSuccess()}>Login With Microsoft</Button>
        </div>
        </div>
    </LoginWrapper>
    );
}

export default AuthComponent;