import axios from 'axios';
import config from '../../../config/config';

import { notification } from 'antd';

export const addSection = (token, body, id, section_id, callback, previewCallback) => {
    axios.post(config.api.base_url + config.api.add_section + '?id='
    + id + '&section_id=' + section_id, body, {headers: {authorization: token}})
    .then(response => {
        notification.success({
            message: 'Success',
            description: response.data && response.data.message ? response.data.message : 'Successfully added a section!'
        })
        callback();
        if(previewCallback)
            previewCallback(section_id, id, body.title);
    }).catch(error => {
        notification.error({
            message: 'Error!',
            description: 'Something went wrong. Please contact your admin!'
        });
    });
}

export const uploadImage = (file, report_id, version, section_id, images, index, callback) => {
    let data = new FormData();
    data.append('attachment', file.file);
    axios.post(config.api.base_url + config.api.upload_img + '?id=' + report_id
    + '&version=' + version + '&section_id=' + section_id, data, {headers: {authorization: localStorage.getItem('token')}})
    .then(response => {
        let url = `${response.data.file_url_s3}`;
        images[index] = url;
        file.onSuccess();
        callback(null, url);
    }).catch(error => {
        callback(error);
        notification.error({
            message: 'Error!',
            description: 'Something went wrong. Please contact your admin!'
        })
    })
}

export const previewSection = (section_id, report_id, version, parent_flag, callback) => {
    let preview_url = config.api.base_url + config.api.section_preview + '?id=' + report_id + '&section_id=' + section_id + '&version=' + version;
    if(parent_flag)
        preview_url += '&parent=true'
    axios.get(preview_url, {headers: {authorization: localStorage.getItem('token')}})
    .then(response => {
        if(response.status === 200)
            callback(response.data);
        else {
            notification.error({
                message: 'Error',
                description: 'Unable to fetch section preview'
            })
        }
    })
    .catch(error => {
        console.log(error);
        notification.error({
            message: 'Error',
            description: 'Unable to fetch section preview'
        })
    })
}