import axios from "axios";
import config from "../../../../config/config";

import { notification } from "antd";

export const getSection = (section_id, callback) => {
  axios
    .get(
      `${config.api.base_url}${
        config.api.get_section
      }?section_id=${section_id}`,
      { headers: { authorization: localStorage.getItem("token") } }
    )
    .then((response) => {
      callback(response.data.content, response.data.pages);
    })
    .catch((error) => {
      notification.error({
        message: "Error!",
        description: "Something went wrong. Please contact your admin!",
      });
    });
};

export const getPrevImage = (report_id, version, callback) => {
  axios
    .get(
      `${config.api.base_url}${
        config.api.prev_image
      }?id=${report_id}&version=${version}`,
      { headers: { authorization: localStorage.getItem("token") } }
    )
    .then((response) => {
      callback(response.data);
    })
    .catch((error, response) => {
      console.log(error);
    });
};
