import React, { useState } from "react";
import { Menu, Checkbox, Input, Button, Dropdown } from "antd";

const BatchFilterDropdown = ({ filters, selectedFilters, setSelectedFilter }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState({
    others: false,
    sortedFilters: false,
  });

  const handleCheckboxChange = (checkedValue) => {
    const currentIndex = selectedFilters.indexOf(checkedValue);
    const newSelectedFilters = [...selectedFilters];

    if (currentIndex === -1) {
      newSelectedFilters.push(checkedValue);
    } else {
      newSelectedFilters.splice(currentIndex, 1);
    }

    setSelectedFilter(newSelectedFilters);
  };

  const combineFilters = () => {
    let combinedFilters = [];
  
    if (filters && filters.sortedFilters) {
      const sortedEntries = Object.entries(filters.sortedFilters)
        .flatMap(([year, months]) => 
          Object.entries(months).map(([month, items]) => ({
            year: parseInt(year),
            month: month,
            items: items,
          }))
        );
  
      sortedEntries.sort((a, b) => {
        if (b.year !== a.year) {
          return b.year - a.year;
        } else {
          const monthOrder = {
            January: 0,
            February: 1,
            March: 2,
            April: 3,
            May: 4,
            June: 5,
            July: 6,
            August: 7,
            September: 8,
            October: 9,
            November: 10,
            December: 11,
          };
          return monthOrder[b.month] - monthOrder[a.month];
        }
      });
  
      sortedEntries.forEach(({ items }) => {
        items.forEach((item) => {
          combinedFilters.push({
            value: item.value ? item.value : item.text,
            type: "sortedFilters",
          });
        });
      });
    }
  
    if (filters && filters.others) {
      combinedFilters = combinedFilters.concat(
        filters.others.map((item) => ({
          value: item.value ? item.value : item.text,
          type: "others",
        }))
      );
    }
  
    return combinedFilters;
  };
  

  const filterItems = () => {
    const combinedFilters = combineFilters();
    return combinedFilters.filter((item) =>
      item.value.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const sortFilters = (items) => {
    const selectedItems = items.filter((item) =>
      selectedFilters.includes(item.value)
    );
    const unselectedItems = items.filter(
      (item) => !selectedFilters.includes(item.value)
    );

    return [...selectedItems, ...unselectedItems];
  };

  const renderMenuItems = (items) => {
    const sortedItems = sortFilters(items);
    return sortedItems.length > 0 ? (
      sortedItems.map((item, index) => (
        <div key={`${item.value}` + index} style={{ padding: "4px 0" }}>
          <Checkbox
            checked={selectedFilters.includes(item.value)}
            onChange={() => handleCheckboxChange(item.value)}
          >
            {item.value}
          </Checkbox>
        </div>
      ))
    ) : (
      <div>No Results</div>
    );
  };

  const othersMenu = (
    <Menu>
      {filters && filters.others && filters.others.length > 0 ? (
        filters.others.map((item, index) => (
          <Menu.Item key={`${item.value ? item.value : item.text}` + index}>
            <Checkbox
              checked={selectedFilters.includes(
                item.value ? item.value : item.text
              )}
              onChange={() =>
                handleCheckboxChange(item.value ? item.value : item.text)
              }
            >
              {item.value ? item.value : item.text}
            </Checkbox>
          </Menu.Item>
        ))
      ) : (
        <Menu.Item disabled>No Filters</Menu.Item>
      )}
    </Menu>
  );

  const sortedFiltersMenu = (
    <Menu
      style={{
        maxHeight: "300px",
        overflowY: "auto",
      }}
    >
      {Object.keys(filters && filters.sortedFilters ? filters.sortedFilters : {})
        .sort((a, b) => b - a)
        .map((year) => (
          <Menu.SubMenu key={year} title={year}>
            {Object.keys(filters.sortedFilters[year])
              .sort((a, b) => new Date(`01 ${a} 2000`) - new Date(`01 ${b} 2000`))
              .map((month) => (
                <Menu.SubMenu key={`${year}-${month}`} title={month}>
                  {filters.sortedFilters[year][month].map((item, index) => (
                    <Menu.Item key={`${item.value ? item.value : item.text}` + index}>
                      <Checkbox
                        checked={selectedFilters.includes(
                          item.value ? item.value : item.text
                        )}
                        onChange={() =>
                          handleCheckboxChange(item.value ? item.value : item.text)
                        }
                      >
                        {item.value ? item.value : item.text}
                      </Checkbox>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ))}
          </Menu.SubMenu>
        ))}
    </Menu>
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Input
        placeholder="Search Batches"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div
        style={{
          maxHeight: "350px",
          overflowY: "auto",
          border: "1px solid #d9d9d9",
          padding: "8px",
          borderRadius: "4px",
          background: "#fff",
        }}
      >
        {renderMenuItems(filterItems())}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <Dropdown
          overlay={othersMenu}
          trigger={["click"]}
          placement="topLeft"
          visible={dropdownVisible.others}
          onVisibleChange={(visible) =>
            setDropdownVisible({ ...dropdownVisible, others: visible })
          }
        >
          <Button style={{ width: "45%" }}>Others</Button>
        </Dropdown>
        <Dropdown
          overlay={sortedFiltersMenu}
          trigger={["click"]}
          placement="topRight"
          visible={dropdownVisible.sortedFilters}
          onVisibleChange={(visible) =>
            setDropdownVisible({ ...dropdownVisible, sortedFilters: visible })
          }
        >
          <Button style={{ width: "100%" }}>By Years</Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default BatchFilterDropdown;
