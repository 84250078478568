import React from "react";
import { Select, Input, Typography, InputNumber, Form, Button, notification } from "antd";

const { Text } = Typography;
const Option = Select.Option;

class MetaFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasErrors: false,
      isInFocus: false,
      region_country_mapping: [
        {
          region: "",
          country: "",
        },
      ],
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let hasErrors = false;

    !hasErrors &&
      this.props.form.validateFields((err, values) => {
        if (!err) {
          let form_data = {
            id: this.props.current_report.id,
            sub_category_id: this.props.current_report.sub_category_id,
            category_id: this.props.current_report.category_id,
            title: values.title,
            pages: values.pages,
            region_country_map: this.state.region_country_mapping.reduce(
              (r, { region, country }) => ((r[region] = country), r),
              {}
            ),
          };
          form_data["geography"] = { [values["geography"]]: values.geography2 };

          let sanatized_forecast_period = values.forecast_period.replace(/\s/g, '');
          const pattern = /^(\d{4}|[a-z]{4})-(\d{4}|[a-z]{4})$/;
          if (pattern.test(sanatized_forecast_period)) {
            form_data["forecast_period"] = sanatized_forecast_period;
            this.props.handleMetaAddition(form_data);
          } else {
            notification.error({
              message: "Error",
              description: (
                <p>
                  Invalid forecast period format! <br />
                  <strong>Please use format: XXXX-XXXX</strong> <br />
                  (ex: 2024-2027)
                </p>
              )
            });
          }
        }
      });
  };

  handleGeographyChange = (value) => {
    this.props.form.setFieldsValue({
      geography2: undefined,
    });
    this.props.handleGeographyChange(value);
  };

  handlePageInput = (value) => {
    if (!isNaN(value)) {
      return value;
    } else return value.substring(0, value.length - 1);
  };

  handleAddRegionCountryInputs = () => {
    this.setState((prevstate) => ({
      region_country_mapping: [
        ...prevstate.region_country_mapping,
        { region: "", country: "" },
      ],
    }));
  };

  removeRegionCountryInputs = (e, index) => {
    e.preventDefault();
    let newFormValues = [...this.state.region_country_mapping];
    newFormValues.splice(index, 1);
    this.setState({
      region_country_mapping: newFormValues,
    });
  };

  handleInputChange = (i, e) => {
    let newFormValues = [...this.state.region_country_mapping];
    newFormValues[i][e.target.name] = e.target.value;
    this.setState({
      region_country_mapping: newFormValues,
    });
  };

  isRegionMandatory = () => {
    let required = true;
    let geography_value = this.props.form.getFieldValue("geography");

    if (geography_value != undefined) {
      geography_value != "country" ? (required = true) : (required = false);
    }
    return required;
  };

  handleKeyPress = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div style={{ display: "flex" }}>
          <Form.Item>
            <Text> Report Title: </Text>
          </Form.Item>

          <Form.Item style={{ marginLeft: "4px", width: "1106px" }}>
            {this.props.form.getFieldDecorator("title", {
              initialValue: this.props.title,
              rules: [
                {
                  required: true,
                  message:
                    "Please enter a report title that is below 250 characters!",
                  whitespace: true,
                  max: 250,
                },
              ],
            })(<Input placeholder="Enter report title" />)}
          </Form.Item>
        </div>

        <div style={{ marginTop: "5px", display: "flex" }}>
          <Form.Item>
            <Text> Geography: </Text>
          </Form.Item>

          <Form.Item style={{ marginLeft: "5px" }}>
            {this.props.form.getFieldDecorator("geography", {
              rules: [
                {
                  required: true,
                  message: "Please enter a geography tag!",
                  whitespace: true,
                },
              ],
            })(
              <Select
                showSearch
                placeholder="Geography"
                onChange={(value) => {
                  this.handleGeographyChange(value);
                }}
              >
                <Option key="global"> Global </Option>
                <Option key="region"> Region </Option>
                <Option key="country"> Country </Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item style={{ marginLeft: "5px" }}>
            {this.props.form.getFieldDecorator("geography2", {
              rules: [
                {
                  required: true,
                  message: "Please enter country/region/global!",
                  whitespace: true,
                },
              ],
            })(
              <Select
                showSearch
                placeholder="Please enter a country/region/global"
              >
                {this.props.chosen_options}
              </Select>
            )}
          </Form.Item>

          <Form.Item style={{ marginLeft: "140px" }}>
            <Text>Pages:</Text>
          </Form.Item>
          <Form.Item style={{ marginLeft: "5px" }}>
            {this.props.form.getFieldDecorator("pages", {
              rules: [
                {
                  required: true,
                  message: "Please enter the number of pages!",
                },
              ],
            })(
              <InputNumber
                placeholder="Enter pages"
                formatter={(value) => this.handlePageInput(value)}
                min={1}
              />
            )}
          </Form.Item>
        </div>

        <div>
          {this.state.region_country_mapping.map((item, index) => {
            return (
              <div style={{ marginTop: "5px", display: "flex" }}>
                <div style={{ display: "flex" }}>
                  <label style={{ display: "flex" }}> Region: </label>
                  <div style={{ margin: "0 10px" }}>
                    <Form.Item>
                      {this.props.form.getFieldDecorator(`region-${index}`, {
                        rules: [
                          {
                            required: this.isRegionMandatory(),
                            message: "Please enter region!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Enter Region"
                          style={{ width: "415px" }}
                          value={item.region || ""}
                          onChange={(e) => this.handleInputChange(index, e)}
                          name="region"
                          onFocus={() => this.setState({ isInFocus: true })}
                          onBlur={() => this.setState({ isInFocus: false })}
                        />
                      )}
                      {!item.region && this.state.hasErrors && (
                        <p style={{ color: "red" }}>Please enter region</p>
                      )}
                    </Form.Item>
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <label style={{ display: "flex" }}> Country: </label>
                  <div style={{ margin: "0 10px" }}>
                    <Input
                      placeholder="Enter Country"
                      style={{ width: "415px" }}
                      value={item.country || ""}
                      onChange={(e) => this.handleInputChange(index, e)}
                      name="country"
                    />
                  </div>
                </div>

                {index && this.state.region_country_mapping.length > 1 ? (
                  <Button
                    type="primary"
                    onClick={(e) => this.removeRegionCountryInputs(e, index)}
                  >
                    Remove
                  </Button>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>

        <div style={{ marginTop: "5px", display: "flex" }}>
            <Form.Item>
              <Text>Forecast Period:</Text>
            </Form.Item>
            <div style={{ marginLeft: "10px" }}>
              <Form.Item>
                {this.props.form.getFieldDecorator("forecast_period", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Forecast Period!"
                    },
                  ],
                })(
                  <Input 
                  placeholder="2024-2027"
                  onKeyPress={this.handleKeyPress}/>
                )}
              </Form.Item>
            </div>
          </div>

        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" onClick={this.handleAddRegionCountryInputs}>
            Add
          </Button>
        </Form.Item>

        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: "meta_form" })(MetaFormComponent);
