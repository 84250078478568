import React from "react";
import { Tabs } from "antd";
import { TabContainerWrapper } from "./TabContainerStyle";
import AddReport from "./Add/AddContainer";
import EditReport from "./Edit/EditContainer";
import PreviewReport from "./Preview/PreviewContainer";
import Catalogue from "./Catalogue/CatalogueContainer";

const { TabPane } = Tabs;

class TabContainer extends React.Component {
  render() {
    return (
      <TabContainerWrapper>
        <Tabs defaultActiveKey="1" size="large" destroyInactiveTabPane>
          <TabPane tab="Add" key="1">
            <AddReport
              handleTitleChange={this.props.handleTitleChange}
              triggerPublishButton={this.props.triggerPublishButton}
              handleHome={this.props.handleHome}
              title={this.props.title}
              handleDisableHomeToggle={this.props.handleDisableHomeToggle}
            />
          </TabPane>
          <TabPane tab="Edit" key="2">
            <EditReport
              triggerPublishButton={this.props.triggerPublishButton}
              tab="EDIT"
            />
          </TabPane>
          <TabPane tab="Preview" key="3">
            <PreviewReport
              handleTitleChange={this.props.handleTitleChange}
              tab="PREVIEW"
              title={this.props.title}
            />
          </TabPane>
          <TabPane tab="Catalogue" key="4">
            <Catalogue />
          </TabPane>
        </Tabs>
      </TabContainerWrapper>
    );
  }
}

export default TabContainer;