import axios from 'axios';
import config from '../../../config/config';

import { notification } from 'antd';

export const getTocList = (token, id, version, forecast_period, callback) => {
    axios.get(config.api.base_url + config.api.toc_tree + '?id=' + id + '&version='
    + version, {headers: {authorization: token}})
    .then(response => {
        callback(response.data.toc);
    }).catch(error => {
        console.error(error);
        notification.error({
            message: 'Error!',
            description: 'Something went wrong. Please contact your admin'
        })
    });
}

export const updateSectionTitle = (token, id, version, forecast_period, section_id, new_title, callback) => {
    axios.post(config.api.base_url + config.api.section_title + '?id=' + id + '&version='
    + version + '&section_id=' + section_id, {new_title: new_title}, {headers: {authorization: token}})
    .then(response => {
        getTocList(token, id, version, forecast_period, callback);
    }).catch(error => {
        console.error(error);
        notification.error({
            message: 'Error!',
            description: 'Something went wrong. Please contact your admin'
        })
    })
}

export const insertSection = (token, id, version, forecast_period, section_id, title, mode, callback) => {
    axios.post(config.api.base_url + config.api.insert_section + '?id=' + id + '&version='
    + version + '&section_id=' + section_id + '&mode=' + mode, {title: title}, {headers: {authorization: token}})
    .then(response => {
        callback();
        notification.success({
            message: 'Success',
            description: 'Inserted Section successfully'
        })
    }).catch(error => {
        console.error(error);
        callback(error);
        notification.error({
            message: 'Error!',
            description: 'Something went wrong. Please contact your admin'
        })
    })
}