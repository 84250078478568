import React from "react";
import config from "../../../../config/config";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  Input,
  notification,
  Icon,
  Dropdown,
  Menu,
  Popconfirm,
  message
} from "antd";

import {
  fetchReports,
  exportReports,
  exportxmlReports,
  unpublishReports,
  markAsSent,
  unmarkAsSent,
  fetchFactsetReports,
  exportFactsetReports,
  exportFactsetReportsv24,
  markAsSentFactset,
  unmarkAsSentFactset,
  unpublishReportsFactset,
  deleteFactset,
  fetchSandPReports,
  exportSandPReports,
  markAsSentSandP,
  unmarkAsSentSandP,
  unpublishReportsSandP,
  deleteSandP,
} from "./CatalogueActions";

import {
  CatalogueContainerWrapper,
  TableContainerWrapper,
} from "./CatalogueContainerStyle";

import { fetchBatchesFilter } from "./CatalogueActions";
import BatchFilterDropdown from "./BatchFilterDropdown";

class Catalogue extends React.Component {
  constructor(props) {
    super(props);
    this.popRef = React.createRef();
    this.inputRef = React.createRef();
    this.getColumns = this.getColumns.bind(this);
    this.columns = this.getColumns();
    this.clearFiltersRef = React.createRef();
  }

  state = {
    selectedRowKeys: [],
    selectedRowValues: [],
    down_icon: true,
    data: [],
    filtered_data: [],
    factset: false,
    sandp: false, // s&p client
    factset_data: [],
    factset_filtered_data: [],
    loading: false,
    searchValue: "",
    clientType: "Non FactSet", // "Non Factset" || "FactSet" | "S&P"
    forecast_period: null,
    searchForecast: false,
    batchFilters: [],
    // selectedFilters: [],
  };

  // Update the selected filter state
  // setSelectedFilter = (filters) => {
  //   this.setState({ selectedFilters: filters });
  // };

  getColumns() {
    return [
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => a.title.localeCompare(b.title),
        sortDirections: ["ascend", "descend"],
        render: (text, record) => {
          if (!this.state.factset) {
            return (
              <Link to={"reports/" + record.id + "/" + record.version + "/" + record.forecast_period}>
                {record.title}
              </Link>
            );
          } else {
            return record.title;
          }
        },
      },
      {
        title: "Batch",
        dataIndex: "batch",
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => {
          // Store the reference to clear filters
          this.clearFiltersRef.current = clearFilters;

          return (
            <div style={{ padding: 8 }}>
              <BatchFilterDropdown
                filters={this.state.batchFilters}
                selectedFilters={selectedKeys} // this.state.selectedFilters
                setSelectedFilter={(filter) => {
                  // this.setSelectedFilter(filter);
                  setSelectedKeys([...filter]);
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "10px",
                  marginTop: "10px",
                }}
              >
                <Button
                  type="primary"
                  style={{ width: "50%" }}
                  onClick={() => {
                    clearFilters();
                    // this.setSelectedFilter([]);
                    setSelectedKeys([]);
                  }}
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => {
                    confirm(); // Apply the clear action
                  }}
                >
                  Confirm
                </Button>
              </div>
            </div>
          );
        },
        filterMultiple: true,
        onFilter: (values, record) => {
          // Ensure `values` is an array
          if (!Array.isArray(values)) {
            values = [values];
          }

          if (values.length === 0) {
            return true; // Show all data if no filters are selected
          }

          return values.some((value) => {
            if (value === null || value.toLowerCase() === "n/a") {
              // Handle the case when batch is null or N/A
              return record.batch === null || record.batch.toLowerCase() === 'n/a';
            }
            return record.batch && value
              ? record.batch.toLowerCase() === value.toLowerCase()
              : false;
          });
        },
      },
      {
        title: "Uploaded",
        dataIndex: "uploaded",
        filters: [
          {
            text: "Uploaded",
            value: true,
          },
          {
            text: "Not Uploaded",
            value: false,
          },
        ],
        align: "center",
        filterMultiple: false,
        onFilter: (value, record) =>
          value ? record.uploaded != "" : record.uploaded == "",
      },
      {
        title: "Sent",
        dataIndex: "sent",
        filters: [
          {
            text: "Sent",
            value: true,
          },
          {
            text: "Unsent",
            value: false,
          },
        ],
        filterMultiple: false,
        align: "center",
        onFilter: (value, record) =>
          value ? record.sent != "" : record.sent == "",
      },
      {
        title: (
          <div
            style={{
              width: this.state.clientType === "Non FactSet" ? "260px" : "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center"
            }}
          >
            {
              this.state.searchForecast ? (
                <Input
                  placeholder="Search Forecast Period"
                  onChange={(e) => this.handleSearchForecastPeriod(e.target.value)}
                  value={this.state.forecast_period}
                  allowClear
                  style={{ width: "190px", marginLeft: "20px" }}
                  ref={this.inputRef} 
                />) : (
                <span>Forecast Period</span>
              )
            }
            <Icon 
              className="seach-icon"
              type="search"
              onClick={() => {
                this.setState(prevState => ({
                  searchForecast: !prevState.searchForecast
                }), () => {
                  if (this.state.searchForecast) {
                    this.inputRef.current.focus(); // Focus on the input when searchForecast becomes true
                  }
                })
              }}
            />
          </div>
        ),
        dataIndex: "forecast_period",
        filterMultiple: false,
        align: "center",
      },
      {
        title: "Date",
        dataIndex: "date",
        defaultSortOrder: "descend",
        sorter: (a, b) => new Date(a.isoDate) - new Date(b.isoDate),
      },
    ];
  }

  componentDidMount() {
    this.handleFetchReports();
    this.handleBatchFilters(this.state.clientType); // default is non-factset at first render
    // console.log("base_url", config.api.base_url)
  }

  handleBatchFilters = async (clientType) => {
    try {
      const type =
        clientType === "Non FactSet"
          ? "non-factset"
          : clientType === "FactSet"
          ? "factset"
          : "sandp";

      const response = await fetchBatchesFilter(type);
      const filters = response && response.data ? response.data : [];

      this.setState({
        batchFilters: filters
      });
    } catch (err) {
      console.error("Error fetching batch filters:", err);
      notification.error({
        message: "Error",
        description: "Error fetching batches",
      });
    }
  };

  handleFetchReports = async () => {
    try {
      this.setState({ loading: true });
      let reports = await fetchReports();

      let data = [];
      if (reports.data && !reports.data.data) {
        for (let index in reports.data) {
          data.push({
            title: reports.data[index].title,
            version: reports.data[index].version,
            id: reports.data[index].id,
            isoDate: new Date(reports.data[index].createdAt).toISOString(),
            date: new Date(reports.data[index].createdAt).toDateString(),
            sent: reports.data[index].marked_as_sent ? (
              <Icon type="check" />
            ) : (
              ""
            ),
            uploaded: reports.data[index].file_path ? (
              <Icon type="check" />
            ) : (
              ""
            ),
            batch: reports.data[index].batch
              ? reports.data[index].batch
              : "N/A",
            forecast_period: reports.data[index].forecast_period 
              ? reports.data[index].forecast_period 
              : "N/A"
          });
        }
      }
      this.setState({
        selectedRowKeys: [],
        data: data,
        filtered_data: data,
        loading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
      notification["error"]({
        message: "Error",
        description: "Error fetching reports",
      });
    }
  };

  handleFetchFactsetReports = async () => {
    try {
      this.setState({ loading: true });
      let reports = await fetchFactsetReports();

      let data = [];
      if (reports.data && !reports.data.data) {
        for (let index in reports.data) {
          data.push({
            title: reports.data[index].title,
            version: reports.data[index].version,
            id: reports.data[index].id,
            isoDate: new Date(reports.data[index].createdAt).toISOString(),
            date: new Date(reports.data[index].createdAt).toDateString(),
            sent: reports.data[index].marked_as_sent ? (
              <Icon type="check" />
            ) : (
              ""
            ),
            uploaded: reports.data[index].fileName ? <Icon type="check" /> : "",
            batch: reports.data[index].batch
              ? reports.data[index].batch
              : "N/A",
            forecast_period: reports.data[index].forecast_period 
            ? reports.data[index].forecast_period 
            : "N/A"
          });
        }
      }

      this.setState({
        selectedRowKeys: [],
        factset_data: data,
        factset_filtered_data: data,
        loading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        loading: false,
      });
      notification["error"]({
        message: "Error",
        description: "Error fetching reports",
      });
    }
  };

  handleFetchSandPReports = async () => {
    try {
      this.setState({ loading: true });
      let reports = await fetchSandPReports();

      let data = [];
      if (reports.data && !reports.data.data) {
        for (let index in reports.data) {
          data.push({
            title: reports.data[index].title,
            version: reports.data[index].version,
            id: reports.data[index].id,
            isoDate: new Date(reports.data[index].createdAt).toISOString(),
            date: new Date(reports.data[index].createdAt).toDateString(),
            sent: reports.data[index].marked_as_sent ? (
              <Icon type="check" />
            ) : (
              ""
            ),
            uploaded: reports.data[index].fileName ? <Icon type="check" /> : "",
            batch: reports.data[index].batch
              ? reports.data[index].batch
              : "N/A",
            forecast_period: reports.data[index].forecast_period 
            ? reports.data[index].forecast_period 
            : "N/A"
          });
        }
      }
      
      this.setState({
        selectedRowKeys: [],
        factset_data: data,
        factset_filtered_data: data,
        loading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        loading: false,
      });
      notification["error"]({
        message: "Error",
        description: "Error fetching reports",
      });
    }
  };

  handleFetchFilterOptions = () => {
    let filters = [],
      batch_arr = [];
    for (let data of this.state.filtered_data) {
      if (data.batch) {
        if (batch_arr.indexOf(data.batch) == -1) batch_arr.push(data.batch);
      }
    }
    for (let data of batch_arr) {
      let temp_filter = {
        text: data,
        value: data,
      };
      if (filters.indexOf(temp_filter) == -1) {
        filters.push(temp_filter);
      }
    }
    this.columns[1].filters = filters;
  };

  handleSearch = (value) => {
    this.setState({
      searchValue: value,
    });
    if (this.state.factset || this.state.sandp) {
      let factset_filtered_data = this.state.factset_data.filter((elem) => {
        return elem.title.toLowerCase().includes(value.toLowerCase());
      });

      this.setState({
        factset_filtered_data: factset_filtered_data,
      });
    } else {
      let filtered_data = this.state.data.filter((elem) => {
        return elem.title.toLowerCase().includes(value.toLowerCase());
      });
      this.setState({
        filtered_data: filtered_data,
      });
    }
  };

  handleSearchForecastPeriod = (value) => {
    this.setState({
      forecast_period: value,
    });
    if (this.state.factset || this.state.sandp) {
      let factset_filtered_data = this.state.factset_data.filter((elem) => {
        return elem.forecast_period.toLowerCase().includes(value.toLowerCase());
      });

      this.setState({
        factset_filtered_data: factset_filtered_data,
      });
    } else {
      let filtered_data = this.state.data.filter((elem) => {
        return elem.forecast_period.toLowerCase().includes(value.toLowerCase());
      });
      this.setState({
        filtered_data: filtered_data,
      });
    }
  };

  handleDownIcon = (value) => {
    this.setState({
      down_icon: value,
    });
  };

  handleExport = async (selectedReports) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let forecast_period = elem.includes("N/A") ? null : elem.split("/")[2]; 
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1], forecast_period };
        reports.push(tempObj);
      }
      let response = await exportReports(reports);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Successfully exported the reports",
        });
        let path = config.api.base_url + "/exports/" + response.data.url;
        path = path.replace("/api/exports", "/exports");
        window.open(path);
      }
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Error",
        description: "Error fetching reports",
        duration: 0,
      });
    }
  };


  handleXMLExport = async (selectedReports) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let forecast_period = elem.includes("N/A") ? null : elem.split("/")[2]; 
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1], forecast_period };
        reports.push(tempObj);
      }
      let response = await exportxmlReports(reports);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Successfully exported the reports",
        });
        let path = config.api.base_url + "/exports/" + response.data.url;
        path = path.replace("/api/exports", "/exports");
        window.open(path);
      }
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Error",
        description: "Error fetching reports",
        duration: 0,
      });
    }
  };

  handleFactsetExport = async (selectedReports, isRixmlv24 = false) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let forecast_period = elem.includes("N/A") ? null : elem.split("/")[2]; 
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1], forecast_period };
        reports.push(tempObj);
      }

      let response = isRixmlv24 ? await exportFactsetReportsv24(reports) : await exportFactsetReports(reports);

      notification["success"]({
        message: "Success",
        description: "Successfully exported the reports",
      });
      if (response.status === 200) {
        let path = config.api.base_url + "/exports/" + response.data.url;
        path = path.replace("/api/exports", "/exports");
        window.open(path);
      }
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Error",
        description: "Error fetching reports",
      });
    }
  };

  handleSandPExport = async (selectedReports) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let forecast_period = elem.includes("N/A") ? null : elem.split("/")[2]; 
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1], forecast_period };
        reports.push(tempObj);
      }

      let response = await exportSandPReports(reports);
      notification["success"]({
        message: "Success",
        description: "Successfully exported the reports",
      });
      if (response.status === 200) {
        let path = config.api.base_url + "/exports/" + response.data.url;
        path = path.replace("/api/exports", "/exports");
        window.open(path);
      }
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Error",
        description: "Error fetching reports",
      });
    }
  };

  handleMarkAsSent = async (selectedReports) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1] };
        reports.push(tempObj);
      }
      let response = await markAsSent(reports, this.popRef.current.state.value);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Marked reports as sent",
        });
        this.handleFetchReports();
      } else {
        notification["error"]({
          message: "Error",
          description: response.data.message,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleFactsetMarkAsSent = async (selectedReports) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1] };
        reports.push(tempObj);
      }
      let response = await markAsSentFactset(
        reports,
        this.popRef.current.state.value
      );
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Marked reports as sent",
        });
        this.handleFetchFactsetReports();
      } else {
        notification["error"]({
          message: "Error",
          description: response.data.message,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleSandPMarkAsSent = async (selectedReports) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1] };
        reports.push(tempObj);
      }
      let response = await markAsSentSandP(
        reports,
        this.popRef.current.state.value
      );
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Marked reports as sent",
        });
        this.handleFetchSandPReports();
      } else {
        notification["error"]({
          message: "Error",
          description: response.data.message,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleUnmarkAsSent = async (selectedReports) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1] };
        reports.push(tempObj);
      }
      let response = await unmarkAsSent(reports);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Unmarked reports",
        });
        this.handleFetchReports();
      } else {
        notification["error"]({
          message: "Error",
          description: response.data.message,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleFactsetUnmarkAsSent = async (selectedReports) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1] };
        reports.push(tempObj);
      }
      let response = await unmarkAsSentFactset(reports);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Unmarked reports",
        });
        this.handleFetchFactsetReports();
      } else {
        notification["error"]({
          message: "Error",
          description: response.data.message,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleSandPUnmarkAsSent = async (selectedReports) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1] };
        reports.push(tempObj);
      }
      let response = await unmarkAsSentSandP(reports);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Unmarked reports",
        });
        this.handleFetchSandPReports();
      } else {
        notification["error"]({
          message: "Error",
          description: response.data.message,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleUnpublish = async (selectedReports) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1] };
        reports.push(tempObj);
      }
      let response = await unpublishReports(reports);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Unpublished reports",
        });
        this.handleFetchReports();
      } else {
        notification["error"]({
          message: "Error",
          description: response.data.message,
        });
      }
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Error",
        description: "Error fetching reports",
      });
    }
  };

  handleFactsetUnpublish = async (selectedReports) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1] };
        reports.push(tempObj);
      }
      let response = await unpublishReportsFactset(reports);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Unpublished reports",
        });
        this.handleFetchFactsetReports();
      } else {
        notification["error"]({
          message: "Error",
          description: response.data.message,
        });
      }
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Error",
        description: "Error fetching reports",
      });
    }
  };

  handleSandPUnpublish = async (selectedReports) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1] };
        reports.push(tempObj);
      }
      let response = await unpublishReportsSandP(reports);
      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Unpublished reports",
        });
        this.handleFetchSandPReports();
      } else {
        notification["error"]({
          message: "Error",
          description: response.data.message,
        });
      }
    } catch (error) {
      console.log(error);
      notification["error"]({
        message: "Error",
        description: "Error fetching reports",
      });
    }
  };

  handleFactsetDelete = async (selectedReports) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1] };
        reports.push(tempObj);
      }

      let response = await deleteFactset(reports);

      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Reports Deleted",
        });
        this.handleFetchFactsetReports();
      } else {
        notification["error"]({
          message: "Error",
          description: response.data.message,
        });
      }
    } catch (err) {
      console.log(err);
      notification["error"]({
        message: "Error",
        description: "Error Deleting Reports",
      });
    }
  };

  handleSandPDelete = async (selectedReports) => {
    try {
      let reports = [];
      for (let elem of selectedReports) {
        let tempObj = { id: elem.split("/")[0], version: elem.split("/")[1] };
        reports.push(tempObj);
      }

      let response = await deleteSandP(reports);

      if (response.status === 200) {
        notification["success"]({
          message: "Success",
          description: "Reports Deleted",
        });
        this.handleFetchSandPReports();
      } else {
        notification["error"]({
          message: "Error",
          description: response.data.message,
        });
      }
    } catch (err) {
      console.log(err);
      notification["error"]({
        message: "Error",
        description: "Error Deleting Reports",
      });
    }
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    let selectedRowValues = selectedRows.map(
      (item) => item.forecast_period ? `${item.id}/${item.version}/${item.forecast_period}` : `${item.id}/${item.version}`
    );

    this.setState({
      selectedRowKeys: selectedRowKeys,
      selectedRowValues: selectedRowValues,
    });
  };

  // handleFactsetChecked = (e) => {
  //   this.setState({
  //     factset: e.target.checked,
  //     searchValue: "",
  //   });

  //   if (e.target.checked) {
  //     this.handleFetchFactsetReports();
  //   } else {
  //     this.setState({
  //       factset_filtered_data: [],
  //     });
  //   }
  // };

  handleClientMenuClick = (e) => {
    let factset = false, sandp = false;

    if (e.key === "FactSet") {
      factset = true;
      this.handleFetchFactsetReports();
    } else if (e.key === "S&P") {
      sandp = true;
      this.handleFetchSandPReports(); 
    } else {
      this.setState({
        factset_filtered_data: [],
      });
    }

    // Call the clear filters function
    if (this.clearFiltersRef.current) {
      this.clearFiltersRef.current();
    }

    this.handleBatchFilters(e.key);

    this.setState({
      clientType: e.key,
      factset: factset,
      sandp: sandp,
      searchValue: "",
    });

    message.success(`${e.key} selected`);
  }

  render() {
    const { selectedRowKeys } = this.state;
    const columns = this.getColumns(); 

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const menu = (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            this.state.factset
              ? this.handleFactsetExport(this.state.selectedRowValues)
              : this.state.sandp 
                ? this.handleSandPExport(this.state.selectedRowValues)
                : this.handleExport(this.state.selectedRowValues);
          }}
          style={{ marginLeft: "10px" }}
          disabled={!hasSelected}
        >
          <Icon type="export" />
          {this.state.factset ? "Export Factset" : this.state.sandp ? "Export S&P" : "Export HTML"}
        </Menu.Item>

        <Menu.Item
          key="1.2"
          hidden={!this.state.factset || this.state.sandp}
          onClick={() => { this.handleFactsetExport(this.state.selectedRowValues, true) }}
          style={{ marginLeft: "10px" }}
          disabled={!hasSelected}
        >
          <Icon type="export" />
          Export Refinitiv
        </Menu.Item>

        <Menu.Item
          key="1.5"
          onClick={() => {
            this.handleXMLExport(this.state.selectedRowValues);
          }}
          style={{ marginLeft: "10px" }}
          disabled={!hasSelected}
          hidden={this.state.factset || this.state.sandp}
        >
          <Icon type="export" />
          Export XML
        </Menu.Item>

        <Menu.Item
          style={{ marginLeft: "10px" }}
          key="2"
          onClick={(e) => e.domEvent.stopPropagation()}
          disabled={!hasSelected}
        >
          <Popconfirm
            title={
              <div>
                <span>Batch:</span>
                <Input onClick={(e) => e.stopPropagation()} ref={this.popRef} />
              </div>
            }
            icon={null}
            onConfirm={() =>
              this.state.factset
                ? this.handleFactsetMarkAsSent(this.state.selectedRowValues)
                : this.state.sandp
                  ? this.handleSandPMarkAsSent(this.state.selectedRowValues)
                  : this.handleMarkAsSent(this.state.selectedRowValues)
            }
          >
            <Icon type="check" style={{ marginRight: 8 }} /> Mark as Sent
          </Popconfirm>
        </Menu.Item>

        <Menu.Item
          key="3"
          onClick={() => {
            this.state.factset
              ? this.handleFactsetUnmarkAsSent(this.state.selectedRowValues)
              : this.state.sandp
                ? this.handleSandPUnmarkAsSent(this.state.selectedRowValues)
                : this.handleUnmarkAsSent(this.state.selectedRowValues);
          }}
          style={{ marginLeft: "10px" }}
          disabled={!hasSelected}
        >
          <Icon type="close" /> Unmark as Sent
        </Menu.Item>

        <Menu.Item
          key="4"
          onClick={() => {
            this.state.factset
              ? this.handleFactsetUnpublish(this.state.selectedRowValues)
              : this.state.sandp
                ? this.handleSandPUnpublish(this.state.selectedRowValues)
                : this.handleUnpublish(this.state.selectedRowValues);
          }}
          style={{ marginLeft: "10px" }}
          disabled={!hasSelected}
        >
          <Icon type="stop" /> Deactivate
        </Menu.Item>

        {this.state.factset && (
          <Menu.Item
            key="5"
            style={{ marginLeft: "10px" }}
            disabled={!hasSelected}
          >
            <Popconfirm
              icon={null}
              title={
                <div style={{ textAlign: "center" }}>
                  <h2>Are you sure ?</h2>
                  <span> You won't be able to revert this</span>
                </div>
              }
              okText="Yes"
              cancelText="No"
              onConfirm={() =>
                this.handleFactsetDelete(this.state.selectedRowValues)
              }
            >
              <Icon type="delete" style={{ marginRight: 8 }} /> Delete Factset
            </Popconfirm>
          </Menu.Item>
        )}

        {this.state.sandp && (
          <Menu.Item
            key="5"
            style={{ marginLeft: "10px" }}
            disabled={!hasSelected}
          >
            <Popconfirm
              icon={null}
              title={
                <div style={{ textAlign: "center" }}>
                  <h2>Are you sure ?</h2>
                  <span> You won't be able to revert this</span>
                </div>
              }
              okText="Yes"
              cancelText="No"
              onConfirm={() =>
                this.handleSandPDelete(this.state.selectedRowValues)
              }
            >
              <Icon type="delete" style={{ marginRight: 8 }} /> Delete S&P
            </Popconfirm>
          </Menu.Item>
        )}
      </Menu>
    );

    const items = (
      <Menu onClick={this.handleClientMenuClick}>
        <Menu.Item key="Non FactSet">
          Non FactSet
        </Menu.Item>
        <Menu.Item key="FactSet">
          FactSet
        </Menu.Item>
        <Menu.Item key="S&P">
          S&P
        </Menu.Item>
      </Menu>
    );

    return (
      <TableContainerWrapper>
        <CatalogueContainerWrapper>
          <Input
            placeholder="Search"
            onChange={(e) => this.handleSearch(e.target.value)}
            value={this.state.searchValue}
            allowClear
            style={{ width: "325px" }}
          />
          <Dropdown overlay={menu} trigger={["click"]} disabled={!hasSelected}>
            <Button
              type="primary"
              style={{ marginLeft: "10px" }}
              onFocus={() => {
                this.handleDownIcon(!this.state.down_icon);
              }}
              onBlur={() => {
                this.handleDownIcon(!this.state.down_icon);
              }}
            >
              <Icon
                style={{
                  fontSize: 12,
                  transform: `rotate(${this.state.down_icon ? "0deg" : "180deg"
                    })`,
                  transition: "300ms ease all",
                }}
                type="down"
              />
              Actions
            </Button>
          </Dropdown>

          {/* <Checkbox
            className="factset_checkbox"
            onChange={this.handleFactsetChecked}
            checked={this.state.factset}
            style={{
              fontSize: "25px",
              fontWeight: "500",
              display: "block",
              textAlign: "center",
            }}
          >
            FactSet
          </Checkbox> */}

          <Dropdown overlay={items} className="client_dropdown">
            <Button style={{ fontSize: "20px", fontWeight: "500" }}>
              {this.state.clientType} <Icon type="down" />
            </Button>
          </Dropdown>
        </CatalogueContainerWrapper>

        <div>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
          </span>
        </div>

        {this.state.searchValue || this.state.forecast_period ? (
          <Table
            columns={columns}
            rowSelection={rowSelection}
            rowKey={(i) => i.forecast_period ? `${i.id}/${i.version}/${i.forecast_period}`: `${i.id}/${i.version}`}
            loading={this.state.loading}
            dataSource={
              (this.state.factset || this.state.sandp)
                ? this.state.factset_filtered_data
                : this.state.filtered_data
            }
            pagination={{
              pageSizeOptions: ["10", "20", "50"],
              showSizeChanger: true,
              locale: { items_per_page: "" },
            }}
          />
        ) : (
          <Table
            columns={columns}
            rowSelection={rowSelection}
            rowKey={(i) => i.forecast_period ? `${i.id}/${i.version}/${i.forecast_period}`: `${i.id}/${i.version}`}
            loading={this.state.loading}
            dataSource={
              (this.state.factset || this.state.sandp) ? this.state.factset_data : this.state.data
            }
            pagination={{
              pageSizeOptions: ["10", "20", "50"],
              showSizeChanger: true,
              locale: { items_per_page: "" },
            }}
          />
        )}
      </TableContainerWrapper>
    );
  }
}

export default Catalogue;
