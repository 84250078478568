/**
 * **Convert S3 url to filename.extension**
 * @param {*} url 
 * @returns 
 */
export const transformFilename = (url) => {
    if (url && typeof url === 'string' && url.length > 0 && url.includes('https://digitizer.s3.amazonaws.com/')) {
        // Extract the filename from the URL
        const filename = url.substring(url.lastIndexOf('/') + 1);

        // Extract the parts of the filename and the extension
        const [name, idWithExtension] = filename.split('_');
        const [id, extension] = idWithExtension.split('.');

        return `${name}.${extension}`;
    } else {
        return url;
    }
}