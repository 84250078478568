import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ContentHeader from './ContentHeader/ContentHeader';
import SectionContainer from './Section/SectionContainer';
import { addSection, previewSection } from './ContentContainerActions';
import { ContentWrapper } from './ContentContainer.style';
import { notification, Form } from 'antd';

class ContentContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submit_form: false,
            tables: [],
            images: [],
            tree_flag: false,
            collapsed: [],
            previous_image:""
        }
        this.setState = this.setState.bind(this);
    }

    reinitializeArrays = () => {
        this.setState({
            tables: [],
            images: []
        });
    }

    handleCollapsedSections = (value) => {
        this.setState({
            collapsed: value
        })
    }

    handleCollapse = (value) => {
        let collapsed = [];
        let keys = this.props.form.getFieldValue('keys')
        if(value)
            collapsed = keys.map((elem) => true)
        else
            collapsed = keys.map((elem) => false)
        this.setState({
            collapsed: collapsed
        })
    }

    updateSubmitForm = (value, parent) => {
        this.setState({...this.state, submit_form: value, tree_flag: parent});
    }

    setImageIndex = (index, value) => {
        let temp_images = this.state.images;
        temp_images[index] = value;
        this.setState({
            ...this.state,
            images: temp_images
        })
    }

    removeImage = (index) => {
        let temp_images = this.state.images;
        delete temp_images[index];
        this.setState({
            ...this.state,
            images: temp_images
        });
    }

    setTableIndex = (index, value) => {
        let temp_tables = this.state.tables;
        temp_tables[index] = value;
        this.setState({
            ...this.state,
            tables: temp_tables
        });
    }

    handleAddSection = (values, move_flag=true, preview_flag=false, section_id=this.props.section_id, title=this.props.title, save_flag=false, keys) => {
        let token = localStorage.getItem('token');
        let formatted_values = this.formatFormValues(values, save_flag, keys);
        let id = this.props.match.params.report_id;
        let body = {
            title: title,
            content: formatted_values,
        }
        body['pages'] = values.pages || null;
        let preview = preview_flag ? this.handlePreviewSection : null;
        addSection(token, body, id, section_id, move_flag ? this.onAddSection : () => {}, preview);
        if(move_flag)
            this.props.setMoveFlag(true);
    }

    onAddSection = () => {
        this.props.set_prev_location(this.props.toc_location);
    }

    formatFormValues = (values, save_flag=false, keys) => {
        let content = [], images = [], tables = [];
        if(save_flag) {
            images = keys.map((key) => { return this.state.images[key]});
            tables = keys.map((key) => { return this.state.tables[key]});
        }
        for(let item in values.elements) {
            switch(values.elements[item]) {
                case 'Heading':
                case 'Heading2':
                case 'Heading3':
                case 'Paragraph':
                case 'List':
                case 'Section-heading':
                    content.push({type: values.elements[item], value: values.values[item]});
                    break;
                case 'Image':
                    this.pushImage(content, values, item, save_flag, images);
                    break;
                case 'Table':
                    this.pushTable(content, values, item, save_flag, tables);
                    break;
                default:
                    break;
            }
        }

        return content;
    }

    handlePreviewSection = (section_id, report_id, title) => {
        previewSection(section_id, report_id, this.props.match.params.version, this.state.tree_flag, (response) => {
            if(response.htmlString) {
                let new_window = window.open('');
                new_window.document.body.innerHTML = response.htmlString;
                new_window.document.title = title;
            } else {
                notification.error({
                    message: 'Error',
                    description: 'No valid content to preview'
                });
            }
        })
    }

    pushImage = (content, values, index, save_flag=false, images=[]) => {
        let temp_image = null;
        if(save_flag)
            temp_image = images[index];
        else
            temp_image = this.state.images[index];
        let image_to_push = {
            url: temp_image,
            meta: {
                heading: values.heading[index],
                source: values.source[index],
                size: values.size[index]
            }
        }

          if(values&&values.image && Array.isArray(values.image) && values.image[values.image.length-1] && values.image[values.image.length-1].file&& values.image[values.image.length-1].file.name)
            this.setState({previous_image:values.image[values.image.length-1].file.name})
          

        content.push({type: values.elements[index], value: image_to_push});
    }

    pushTable = (content, values, index, save_flag=false, tables=[]) => {
        let temp_table = null;
        if(save_flag)
            temp_table = tables[index];
        else
            temp_table = this.state.tables[index];
        let table_to_push = {
            table: temp_table,
            meta: {
                heading: values.heading[index],
                table_has_header: values.checkbox[index] ? true: false,
                source: values.source[index],
                rows: temp_table.length,
                columns: temp_table[0].length
            }
        };
        content.push({type: values.elements[index], value: table_to_push});
    }

    render() {
        return (
            <ContentWrapper>
                <ContentHeader title={this.props.title}
                updateSubmitForm={this.updateSubmitForm} 
                toc_location={this.props.toc_location} handleCollapse={this.handleCollapse}/>
                <SectionContainer section_id={this.props.section_id} handleCollapsedSections={this.handleCollapsedSections}
                add_section={this.handleAddSection} submit_form={this.state.submit_form} setMenuSelect={this.props.setMenuSelect} 
                menu_select={this.props.menu_select} form={this.props.form} updateSubmitForm={this.updateSubmitForm} collapsed={this.state.collapsed}
                move_flag={this.props.move_flag} title={this.props.title} tree_flag={this.state.tree_flag}
                tables={this.state.tables} images={this.state.images} setImageIndex={this.setImageIndex} removeImage={this.removeImage} 
                setTableIndex={this.setTableIndex} toc_location={this.props.toc_location}
                reinitializeArrays={this.reinitializeArrays} 
                previous_image={this.state.previous_image} 
                setState={this.setState}
                />
            </ContentWrapper>
        );
    }
}

export default Form.create()(withRouter(ContentContainer));