import React from 'react';
import { withRouter } from 'react-router-dom';
import { uploadImage } from '../../ContentContainerActions';
import { getPrevImage } from '../SectionContainerActions';
import config from '../../../../../config/config';
import { HotTable } from '@handsontable-pro/react';
import { Draggable } from 'react-beautiful-dnd';
import { Input, Select, Form, Upload, Button, Icon, Table, Checkbox, Radio, notification } from 'antd';
import { transformFilename } from '../../../../../utils/helpers';

const Option = Select.Option;
const { TextArea } = Input;
const section_elements = ['Paragraph', 'Section-heading', 'List', 'Image', 'Heading', 'Heading2', 'Heading3', 'Table'];
let hotTableRefs = [];

class SectionFormItems extends React.Component {

    componentDidMount () {
        let keys = this.props.form.getFieldValue('keys');
        let collapsed = keys.map((elem) => {return false});
        this.props.handleCollapsedSections(collapsed)
    }

    componentDidUpdate(prevProps) {
        if(prevProps.section_id !== this.props.section_id) {
            this.props.emptyFileList();
        }
    }

    addTableRef = (index) => {
        let tempRef = React.createRef();
        hotTableRefs[index] = tempRef;
    }

    elementChange = (index, value, k) => {
        this.props.elements[index] = value.target.value;
        this.props.removeImage(k);
        this.props.setFileList([], k);
        this.props.form.setFieldsValue({
            [`values[${k}]`]: ''
        })
    }

    tabInterceptor = (e, k, index) => {
        if(e.keyCode === 9) {
            e.preventDefault();
            let start = this.props.input_refs[index].current.textAreaRef.selectionStart;
            let end = this.props.input_refs[index].current.textAreaRef.selectionEnd;
            let val = e.target.value;
            let new_value = val.substring(0, start) + '\t' + val.substring(end);
            this.props.form.setFieldsValue({
                [`values[${k}]`]: new_value
            }, () => {
                this.props.input_refs[index].current.textAreaRef.selectionStart = this.props.input_refs[index].current.textAreaRef.selectionEnd = start + 1;
            })
        }
        if(e.keyCode === 13 && !e.shiftKey) {
            const { getFieldValue } = this.props.form;
            if(k === getFieldValue('elements').length - 1) {
                e.preventDefault();
                this.props.add_item();
            }
        }
    }

    getDynamicFormItems = (index, k, display_none) => {
        switch(this.props.elements[index]) {
            case 'Heading':
            case 'Heading2':
            case 'Heading3':
            case 'Paragraph':
            case 'List':
            case 'Section-heading':
                return this.getGeneralItems(k, index, this.props.elements[index], display_none);
            case 'Image':
                return this.getImageItems(k, index, display_none);
            case 'Table':
                return this.getTableItems(index, k, display_none);
            default:
                break;
        }
    }

    getDynamicFormItemsCollapsed = (index, k) => {
        let headings = this.props.form.getFieldsValue()['heading'];
        let values = this.props.form.getFieldsValue()['values'];
        let color = (headings && headings[k]) || ( values && values[k]) ? '' : '#f5222d';
        return (<span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '70%', color: color}}>{headings && headings[k] ? headings[k]: values && values[k] ? values[k] : 'Please enter content!'}</span>)
    }

    handleSectionCollapse = (index) => {
        let collapsed = this.props.collapsed;
        if(collapsed[index]) {
            collapsed[index] = !collapsed[index]
        } else {
            collapsed[index] = true;
        }
        this.props.handleCollapsedSections(collapsed);
    }

    changeUpload = (k) => {
        this.props.removeImage(k);
    }

    getGeneralItems = (k, index, item, display_none) => {
        const { getFieldDecorator } = this.props.form;
        let rules = {
            rules: [this.props.elements[k] === 'Table' ? 
            {whitespace: true} :
            {required: true, whitespace: true, message: 'This item is required!'}]
        }
        if(!this.props.input_refs[index])
            this.props.input_refs[index] = React.createRef();
        return (
            <Form.Item label="Value" className="element-value" style={display_none ? {display: 'none'} : {}}>
                {getFieldDecorator(`values[${k}]`, rules)(
                    <TextArea maxLength={'50000'} id="enable-tab"
                    rows={item === 'List' || item === 'Paragraph' ? 9 : 3}
                    ref={this.props.input_refs[index]} className={`table${k}`}
                    onKeyDown={(e) => this.tabInterceptor(e, k, index)} />
                )}
            </Form.Item>
        )
    }

    customRequest = (file, k) => {
        this.props.changeUploadProgress(true);
        this.props.setImageIndex(k, file.file.name);
        uploadImage(file, this.props.match.params.report_id, this.props.match.params.version, this.props.section_id, this.props.images, k, (err, url) => {
            this.props.changeUploadProgress(false);
            if(err)
                this.props.removeImage(k);
            else {
                getPrevImage(this.props.match.params.report_id, this.props.match.params.version, (url) => {
                    let filename = url.file_name;
                    // filename = filename.split('_');
                    // if(filename && filename.length)
                    //     filename.slice(0, 1);
                    // filename = filename.join('_');        
                    this.props.setPrevImage(filename);
                    this.props.setState({
                        previous_image: filename
                    })
                })
            }
        });
    }

    getImageItems = (k, index, display_none) => {
        let image_path = `${config.api.base_url.substring(0, config.api.base_url.length - 4)}/uploads/${this.props.match.params.report_id}/${this.props.match.params.version}/${this.props.images[k]}`
        const { getFieldDecorator } = this.props.form;
        const validator = (rules, values, cb) => {
            this.props.images[k] ? cb(): cb('Please provide a valid image!')
        };
        
        return (
            <div className="row-wrap image-container" style={display_none ? {display: 'none'} : {}}>
                <Form.Item label="Heading" className="image-item">
                    {getFieldDecorator(`heading[${k}]`, {
                    rules: [{ required: true, message: 'This item is required!', whitespace: true}],
                })(
                        <Input placeholder="Heading"/>
                    )}
                </Form.Item>
                <Form.Item label="Source" className="image-item">
                    {getFieldDecorator(`source[${k}]`, {
                    rules: [{ required: true, message: 'This item is required!', whitespace: true}],
                    initialValue: "Mordor Intelligence"
                })(
                        <Input placeholder="Source"/>
                    )}
                </Form.Item>
                <Form.Item label="Size" className="image-item">
                    {getFieldDecorator(`size[${k}]`, {
                        initialValue: "Medium"
                    })(
                        <Select placeholder="Image size">
                            <Option key="Low">Low</Option>
                            <Option key="Medium">Medium</Option>
                            <Option key="High">High</Option>
                        </Select>
                    )}
                </Form.Item>
                <div className="image-wrapper">
                    <Form.Item className="image-upload">
                        {getFieldDecorator(`image[${k}]`, {
                            rules: [{validator: validator, whitespace: true}]
                    })(
                        <Upload {...{
                            name: 'files',
                            accept: 'image/gif, image/jpeg, image/svg+xml, image/png',
                            customRequest: (file) => this.customRequest(file, k),
                            onChange: (file) => {this.props.setFileList([file.file], k)},
                        }} fileList={this.props.file_list[k]} showUploadList={{showPreviewIcon: true, showRemoveIcon: false}}>
                            <Button>
                            <Icon type="upload" /> Click to Upload
                            </Button>
                        </Upload>
                    )}
                    </Form.Item>
                    { this.props.images[k] ?  !this.props.upload_in_progress && this.props.images[k] ? (<div className="image-tag">
                        {/* <img src={`${config.api.base_url.substring(0, config.api.base_url.length - 4)}/uploads/${this.props.match.params.report_id}/${this.props.match.params.version}/${this.props.images[k]}`} alt={'Image preview loading...'} style={{height: '200px', width: 'auto', marginLeft: '20px'}} /> */}
                        <img 
                            src={this.props.images[k].includes('https://digitizer.s3.amazonaws.com/') 
                                ? this.props.images[k] 
                                : `${config.api.base_url.substring(0, config.api.base_url.length - 4)}/uploads/${this.props.match.params.report_id}/${this.props.match.params.version}/${this.props.images[k]}`} 
                            alt={'Preview Uploaded Img loading...'} 
                            style={{ height: '200px', width: 'auto', marginLeft: '20px' }} 
                        />
                    </div>) : "" :
                    (<div className="prev-image">
                        <b>Last uploaded image : </b>
                        <span>
                            {
                                this.props.images[this.getPrevKValue(k)] || this.props.previous_image
                                    ? transformFilename(this.props.images[this.getPrevKValue(k)]) || transformFilename(this.props.previous_image)
                                    : this.props.prev_image
                                        ? transformFilename(this.props.prev_image)
                                        : ''
                            }
                        </span>
                    </div>)}
                </div>
            </div>
        )
    }

    getPrevKValue = (k) => {
        let prev_index = k - 1;
        let elements = this.props.form.getFieldValue('elements');
        while(elements[prev_index] == null && prev_index > -1) {
            prev_index--;
        }
        return prev_index;
    }

    getTableItems = (index, k, display_none) => {
        const { getFieldDecorator } = this.props.form;
        this.addTableRef(k);
        const data = this.props.tables[k] ? this.props.tables[k] : [[null, null],[null, null]];
        return (
            <div className="row-wrap table-container" style={display_none ? {display: 'none'} : {}}>
                <Form.Item label="Heading" className="table-item">
                    {getFieldDecorator(`heading[${k}]`, {
                    rules: [{ required: true, message: 'This item is required!', whitespace: true }],
                })(
                        <Input placeholder="Heading"/>
                    )}
                </Form.Item>
                <Form.Item label="Source" className="table-item">
                    {getFieldDecorator(`source[${k}]`, {
                    rules: [{ required: true, message: 'This item is required!', whitespace: true }],
                    initialValue: "Mordor Intelligence"
                })(
                        <Input placeholder="Source"/>
                    )}
                </Form.Item>
                <Form.Item className="table-item">
                    {getFieldDecorator(`checkbox[${k}]`, {
                        valuePropName: 'checked',
                        initialValue: true
                    })(
                        <Checkbox>Table has header</Checkbox>,
                    )}
                </Form.Item>
                <div style={{minWidth: '250px', width: 'auto', minHeight: '120px', height:'auto', overflow: 'auto'}}>
                    <HotTable
                        ref={hotTableRefs[k]}
                        afterChange={() => {this.handleTableDataChange(k)}}
                        settings= {{
                            data: data,
                            contextMenu: true,
                            autoColumnSize: true,
                            autoRowSize: true,
                            minSpareRows: 0,
                            autoWrapCol: true,
                            licenseKey: config.security.handsOnTable
                        }}
                        />
                </div>
            </div>
        )
    }

    handleTableDataChange = (k) => {
        if(hotTableRefs[k] && hotTableRefs[k].current)
            this.props.setTableIndex(k, hotTableRefs[k].current.hotInstance.getData());
    }

    validateClipboardTable = (table) => {
        if(!Array.isArray(table) || !Array.isArray(table[0])) {
            return false;
        }
        let cols = table[0].length;
        for(let row of table) {
            if(row.length !== cols) {
                notification.error({
                    message: 'Error!',
                    description: 'Uneven table'
                })
                return false;
            }
        }
        return true;
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });
        const keys = getFieldValue('keys');
        const element_options = section_elements.map(item => <Radio.Button key={item} value={item}>{item}</Radio.Button>)
        const midSectionButton = (k, index) => {
            if(index < keys.length - 1) {
                return (
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: 10, width: '90%', marginLeft: 9}}>
                        <Icon type="plus-circle" style={{fontSize: 22}} onClick={() => this.props.addMidItem(k, index)}/>
                    </div>
                )
            } else {
                return ''
            }
        }
        const formItems = keys.map((k, index) =>{
            if(!this.props.collapsed[index]) {
                return  (
                    <Draggable draggableId={k.toString()} index={index}>
                        {provided => (
                            <div key={k} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <div className="element-wrapper" >
                            <div className="element-type" >
                                <Form.Item label="Content-type" style={{width: 'auto'}}>
                                    {getFieldDecorator(`elements[${k}]`, {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        initialValue: this.props.elements[index],
                                        rules: [{
                                            required: true,
                                            whitespace: true,
                                            message: "Please input element type!",
                                        }],
                                    })(
                                        <Radio.Group style={{width: '100%'}} onChange={(value) => this.elementChange(index, value, k)} buttonStyle={"solid"}>
                                            {element_options}
                                        </Radio.Group>
        
                                    )}
                                </Form.Item>
                                <div>
                                    <Icon className="dynamic-collapse-button" type="up"
                                        onClick={() => this.handleSectionCollapse(index)} />
                                    <Icon className="dynamic-delete-button" type="close" style={{marginLeft: 20}}
                                        onClick={() => this.props.remove_item(k, index)} />
                                </div>
                            </div>
                            {this.getDynamicFormItems(index, k)}
                            </div>
                            {midSectionButton(k, index)}
                        </div>
                        )}
                    </Draggable>
                );
            } else {
                return (
                    <Draggable draggableId={k.toString()} index={index}>
                        {provided => (
                            <div key={k} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <div className="element-wrapper" style={{padding: 0}}>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <div style={{width: '12%', padding: '10px 20px', backgroundColor: '#1890ff', borderRadius: '10px 0px 0px 10px', color: 'white'}}>
                                        <span style={{display: 'flex', justifyContent: 'center'}}>{this.props.elements[index]}</span>
                                    </div>
                                    {this.getDynamicFormItemsCollapsed(index, k)}
                                    <Form.Item label="Content-type" style={{width: 'auto', display: 'none'}}>
                                        {getFieldDecorator(`elements[${k}]`, {
                                            validateTrigger: ['onChange', 'onBlur'],
                                            initialValue: this.props.elements[index],
                                            rules: [{
                                                required: true,
                                                whitespace: true,
                                                message: "Please input element type!",
                                            }],
                                        })(
                                            <Radio.Group style={{width: '100%'}} onChange={(value) => this.elementChange(index, value, k)} buttonStyle={"solid"}>
                                                {element_options}
                                            </Radio.Group>
            
                                        )}
                                    </Form.Item>                                  
                                    <div style={{padding: '10px 20px'}}>
                                        {this.getDynamicFormItems(index, k, true)}
                                        <Icon className="dynamic-collapse-button" type="up"
                                            onClick={() => this.handleSectionCollapse(index)} 
                                            style={{fontSize: 12, transform: `rotate(${!this.props.collapsed[index]?'0deg':'180deg'})`, transition: '300ms ease all'}}
                                            />
                                        <Icon className="dynamic-delete-button" type="close" style={{marginLeft: 20}}
                                            onClick={() => this.props.remove_item(k, index)} />
                                    </div>
                                </div>
                            </div>
                            {midSectionButton(k, index)}
                            </div>
                            )}
                    </Draggable>
                ); 
            }
        });

        return (
            <div ref={this.props.innerRef}>
                {formItems}
            </div>
        );
    }
};

export default withRouter(SectionFormItems);