import React from 'react';
import { history } from '../../../../history';
import { Select, notification } from 'antd';
import { fetchReports } from './EditActions';
import { EditContainerWrapper } from './EditContainerStyle';

const Option = Select.Option;

class EditReport extends React.Component {

    state = {
        data: [],
        options: [],
        value: undefined,
    }

    componentDidMount = async () => {
        try {
            let response = await fetchReports();
            this.setState({...this.state, data: response.data, options: response.data});
        } catch (error) {
            console.log(error);
            notification['error']({
                message: 'Error',
                description: 'Error fetching reports',
            });
        }
        
    }
    
    handleChange = (value) => {
        let report = this.findReport(value);
        let forecast_period = report.forecast_period ? report.forecast_period : null;
        let link = this.props.tab === 'EDIT' ? '/reports/' + value + '/' + forecast_period : '/preview/' + value;
        this.setState({ value: value });
        if(this.props.tab === 'EDIT') {
            this.props.triggerPublishButton(report.title);
        } else {
            this.props.handleTitleChange(report.title);
        }
        history.push(link);
    } 

    findReport = (id) => {
        for(let elem of this.state.data) {
            if((elem.id + '/' + elem.version)== id) {
                return elem;
            }
        }
        return null;
    }

    filterReports = (query) => {
        if(query) {
            this.setState({
                ...this.state,
                options: this.state.data.filter(elem => {
                        return elem.title.toLowerCase().indexOf(query.toLowerCase()) != -1;
                    })
            })
        } else {
            this.setState({
                ...this.state,
                options: this.state.data
            })
        }
    }

    render() {
        const options = this.state.options && this.state.options.length ? this.state.options.map(elem => <Option key={elem.id + '/' + elem.version}>{elem.title + ' v' + elem.version}</Option>) : [];

        return (
            <div>
            <EditContainerWrapper>
                <Select
                showSearch
                value={this.state.value ? this.state.value : undefined}
                placeholder= 'Enter a search query'
                defaultActiveFirstOption={false}
                allowClear
                onSearch={this.filterReports}
                showArrow={false}
                filterOption={false}
                onChange={this.handleChange}
                notFoundContent={null}
                >
                {options}
                </Select>
            </EditContainerWrapper>
            </div>
        )
    }
}

export default EditReport;