import axios from "axios";
import config from "../../../../../config/config";

export const TOCUpload = (form_data, report_id, report_version, forecast_period) => {
  return axios.post(
    config.api.base_url +
      config.api.toc_upload +
      "?id=" +
      report_id +
      "&version=" +
      report_version +
      "&forecast_period=" +
      forecast_period,
    form_data,
    { headers: { authorization: localStorage.getItem("token") } }
  );
};
