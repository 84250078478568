import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Icon, Tooltip, InputNumber, notification, Modal } from 'antd';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import SectionFormItems from './SectionFormItems/SectionFormItems';
import { getSection, getPrevImage } from './SectionContainerActions';

import { SectionWrapper } from './SectionContainer.style';

const confirm = Modal.confirm;

let id = 0;
let initial_elements = [];
let input_refs = [];
let data = [];
let columns = [];

class SectionContainer extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            fileList: {},
            current_hash: null,
            prev_pages: null,
            prev_image: null,
            upload_in_progress: false
        }
        this.draggableRef = React.createRef();
    }

    componentDidMount () {
        window.addEventListener("beforeunload", (ev) => 
        { 
            let values = this.props.form.getFieldsValue();
            if(values && ((values.keys && values.keys.length) || values.pages)) {
                if(this.state.prev_pages) {
                    values['pages'] = this.state.prev_pages;
                }
                if(this.state.current_hash && (JSON.stringify(values, Object.keys(values).sort()) != this.state.current_hash)) {
                    ev.preventDefault();
                    return ev.returnValue = 'Are you sure you want to close there is unsaved data left?';
                }
            }
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.section_id !== this.props.section_id) {
            getPrevImage(this.props.match.params.report_id, this.props.match.params.version, (url) => {
                let filename = url.file_name;
                // filename = filename.split('_');
                // if(filename && filename.length)
                //     filename.slice(0, 1);
                // filename = filename.join('_');
                this.setPrevImage(filename);
                this.props.setState({
                    previous_image: filename
                })
            })
            let data = this.props.form.getFieldsValue();
            if(data && ((data.keys && data.keys.length) || data.pages)) {
                if(this.state.prev_pages) {
                    data['pages'] = this.state.prev_pages;
                }
                if(this.state.current_hash && (JSON.stringify(data, Object.keys(data).sort()) != this.state.current_hash)) {
                    window.addEventListener("beforeunload", (ev) => 
                    { 
                        ev.preventDefault();
                        return ev.returnValue = 'Are you sure you want to close? There is unsaved data left.';    
                    });
                } else {
                    window.removeEventListener("beforeunload", (ev) => 
                    { 
                        ev.preventDefault();
                        return ev.returnValue = 'Are you sure you want to close? There is unsaved data left.';    
                    });
                }
            } else {
                window.removeEventListener("beforeunload", (ev) => 
                { 
                    ev.preventDefault();
                    return ev.returnValue = 'Are you sure you want to close? There is unsaved data left.';    
                })
            }
            if(this.props.menu_select) {
                this.props.form.validateFieldsAndScroll((err, values) => {
                    let err_boolean = this.state.prev_pages ? !err : err ? !err.hasOwnProperty('heading') && !err.hasOwnProperty('values') && !err.hasOwnProperty('image') : true;
                    if(err_boolean && values && ((values.keys && values.keys.length) || values.pages)) {
                        if(this.state.prev_pages) {
                            values['pages'] = this.state.prev_pages;
                        }
                        if(this.state.current_hash && (JSON.stringify(values, Object.keys(values).sort()) != this.state.current_hash)) {
                            this.showSaveConfirm(values, prevProps.section_id, prevProps.title);
                        } else {
                            this.initializeSection();
                        }
                    } else {
                        this.initializeSection();
                    }
                }) 
            } else {
                this.initializeSection();
            }
        } 
        if(this.props.submit_form) {
            this.handleSubmit(null, false, true);
            this.props.updateSubmitForm(false, this.props.tree_flag);
        }
    }

    reinitializeFields = () => {
        id = 0;
        initial_elements = [];
        input_refs = [];
        data = [];
        columns = [];
        this.props.reinitializeArrays();
        this.props.form.resetFields();
    }

    changeUploadProgress = (value) => {
        this.setState({
            ...this.state,
            upload_in_progress: value
        })
    }

    showSectionDeleteConfirm = (k, index) => {
        confirm({
            title: 'Are you sure delete this content?',
            content: 'This is an irreversible process',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk : () => {
                this.remove(k, index);
            },
            onCancel () {
            },
        });
    }

    setFileList = (file, k) => {
        let tempFileList = this.state.fileList; 
        tempFileList[k] = file;
        this.setState({
            ...this.state,
            fileList: tempFileList
        });
    }

    setPrevImage = (image) => {
        this.setState({
            ...this.state,
            prev_image: image
        })
    }

    emptyFileList = () => {
        this.setState({
            ...this.state,
            fileList: {}
        });
    }

    showSaveConfirm = (values, section_id, title) => {
        confirm({
            title: 'There are pending changes in the current section. Do you want to save them?',
            okText: 'Yes',
            cancelText: 'No',
            onOk : () => {
                for(let key in values) {
                    if(["pages", "keys"].indexOf(key) == -1) {
                        values[key] = this.remapElemToKeys(values[key]);
                    }
                }
                let keys = this.props.form.getFieldValue('keys');
                this.props.add_section(values, false, false, section_id, title, true, keys);
                this.props.setMenuSelect(false);
                this.initializeSection();
            },
            onCancel: () => {
                this.props.setMenuSelect(false);
                this.initializeSection();
            },
        });
    }
    
    initializeSection = () => {
        this.reinitializeFields();
        getSection(this.props.section_id, this.populateSection);
    }

    handleDragAndDrop = (drag) => {
        if(drag.destination) {
            let keys = this.props.form.getFieldValue('keys');
            let collapsed = this.props.collapsed;
            let temp = collapsed.splice(drag.source.index, 1);
            collapsed.splice(drag.destination.index, 0, temp[0]);
            this.props.handleCollapsedSections(collapsed);
            temp = keys.splice(drag.source.index, 1);
            keys.splice(drag.destination.index, 0, temp[0]);
            temp = initial_elements.splice(drag.source.index, 1);
            initial_elements.splice(drag.destination.index, 0, temp[0]);
            this.props.form.setFieldsValue({
                'keys': keys,
            })
        }
    }

    populateSection = (content, pages) => {
        this.reinitializeFields();
        if(pages)
            this.prePopulatePages(pages);
        for(let item of content) {
            switch(item.type) {
                case 'Heading':
                case 'Heading2':
                case 'Heading3':
                case 'Paragraph':
                case 'List':
                case 'Section-heading':
                    this.add(null, item.type, item.value);
                    break;
                case 'Image':
                    this.prepopulateImage(item.value);
                    break;
                case 'Table':
                    this.prepopulateTable(item.value);
                    break;
                default:
                    break;
            }
        }
        let values = this.props.form.getFieldsValue();
        if(values && (values.keys || values.pages)) {
            this.setState({
                ...this.state,
                current_hash: JSON.stringify(values, Object.keys(values).sort()),
                prev_pages: values.pages ? values.pages : null
            }, () => {
                if(values && values.keys) {
                    let collapsed = [];
                    values.keys.map((elem) => collapsed[elem] = true);
                    this.props.handleCollapsedSections(collapsed);
                }
            })
        }
    }

    remove = (k, index) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        initial_elements.splice(index, 1);
        input_refs.splice(index, 1);
        this.props.removeImage(k);
        let tempImage = null;
        for(let image of this.props.images){
            if(image)
                tempImage = image;
        }
        this.setFileList([], k);
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
        let collapsed = this.props.collapsed;
        collapsed.splice(index, 1);
        this.props.handleCollapsedSections(collapsed);
    }

    add = (event, type, value) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        if(keys.length === 36) {
            notification.warning({
                message: 'Warning!',
                description: 'Too much content in a single section. Consider splitting sections to spread out the data.'
            });
        }
        if(keys.length < 75) {
            const nextKeys = keys.concat(id++);
            let index = nextKeys.length - 1;
            if(index > -1)
                initial_elements[index] = value ? type : this.getAddType(type, index);
            form.setFieldsValue({
                keys: nextKeys,
            }, () => {
                this.setLatestGeneralItemValue(type, value, index);
                this.scrollRef.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            });
        } else {
            notification.error({
                message: 'Error!',
                description: 'Too much content in a single section. Please limit it to 75 containers a section.'
            })
        }
        
    }

    addMidItem = (k, index) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        keys.splice(index+1, 0, id++);
        initial_elements.splice(index+1, 0, 'List');
        input_refs.splice(index+1, 0, null);
        let collapsed = this.props.collapsed;
        collapsed.splice(index+1, 0, false);
        form.setFieldsValue({
            keys
        }, () => {
            if(input_refs[index + 1] && input_refs[index + 1].current && input_refs[index + 1].current.textAreaRef)
                input_refs[index + 1].current.textAreaRef.scrollIntoView({ behavior: "smooth", inline: "nearest", block: "end"})
        });
    }

    setLatestGeneralItemValue = (type, value, index) => {
        if(value) {
            this.props.form.setFieldsValue({
                [`values[${id - 1}]`]: value
            })
        }
        if(input_refs[index - 1] && input_refs[index - 1].current && input_refs[index - 1].current.textAreaRef && !value) {
            this.copyOldValue(this.getAddType(type, index), input_refs[index - 1].current.textAreaRef.selectionStart, index);
        }
    }

    getAddType = (type, index) => {
        let allowed_types = ['Paragraph', 'List', 'Heading', 'Heading2', 'Heading3'];
        if(type) {
            return type;
        } else {
            let elements = this.props.form.getFieldValue('elements');
            let prev_index = id - 2 === -1 ? 0 : id - 2;
            let values = this.props.form.getFieldValue('values');
            if(values && values.length) {
                while(elements[prev_index] == null && prev_index > -1) {
                    prev_index--;
                }
            }
            if(Array.isArray(elements) && elements[prev_index] && allowed_types.includes(elements[prev_index])) {
                return elements[prev_index];
            } else {
                return 'List';
            }
        }
    }

    copyOldValue = (type, selection_start, index = null) => {
        let allowed_types = ['Paragraph', 'List', 'Heading', 'Heading2', 'Heading3'];
        if(allowed_types.indexOf(type) != -1) {
            let values = this.props.form.getFieldValue('values');
            let prev_index = id - 2;
            if(index && this.props.collapsed[index - 1]) {
                return;
            }
            while(values[prev_index] == null && prev_index > -1) {
                prev_index--;
            }
            if(values[prev_index] && prev_index > -1) {
                this.props.form.setFieldsValue({
                    [`values[${id - 1}]`]: values[prev_index].slice(selection_start),
                    [`values[${prev_index}]`]: values[prev_index].substring(0, selection_start)
                })
            }
        }
    }

    prePopulatePages = (pages) => {
        this.props.form.setFieldsValue({
            pages: pages
        });
    }

    prepopulateImage = (item) => {
        this.add(null, 'Image');
        this.props.form.setFieldsValue({
            [`heading[${id - 1}]`]: item.meta.heading,
            [`source[${id - 1}]`]: item.meta.source,
            [`size[${id - 1}]`]: item.meta.size
        });
        this.props.setImageIndex(id - 1, item.url);
    }

    remapElemToKeys = (elems) => {
        let keys = this.props.form.getFieldValue('keys'); 
        return elems ? keys.map((key) => { return elems[key]}) : keys;
    }

    prepopulateTable = (item) => {
        this.props.setTableIndex(id, item.table);
        this.createTable(item.table, id);
        this.add(null, 'Table');
        this.props.form.setFieldsValue({
            [`heading[${id - 1}]`]: item.meta.heading,
            [`source[${id - 1}]`]: item.meta.source,
            [`checkbox[${id - 1}]`]: item.meta.table_has_header,
            [`values[${id - 1}]`]: ''
        });
    }

    createTable = (clipText, k) => {
        columns[k] = [];
        data[k] = [];
        this.createHeaders(clipText, k);
        this.createRows(clipText, k);
    }

    createHeaders = (table, k) => {
        for(let item of table[0]) {
            columns[k].push({
                title: item,
                key: item,
                dataIndex: item
            });
        }
    }

    createRows = (table, k) => {
        for(let i = 1; i < table.length; i++) {
            let row = {};
            for(let item in table[i]) {
                row.key = item;
                row[columns[k][item].dataIndex] = table[i][item];
            }
            data[k].push(row);
        }
    }

    handleSubmit = (e, move_flag, preview_flag) => {
        if(e)
            e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                for(let key in values) {
                    if(["pages", "keys"].indexOf(key) == -1) {
                        values[key] = this.remapElemToKeys(values[key]);
                    }
                }
                let keys = this.props.form.getFieldValue('keys');
                this.props.add_section(values, move_flag, preview_flag, undefined, undefined, true, keys);
                if(values && (values.keys || values.pages)) {
                    this.setState({
                        ...this.state,
                        current_hash: JSON.stringify(values, Object.keys(values).sort()),
                        prev_pages: values.pages ? values.pages : null
                    })
                }
            } else {
                console.log(err);
                notification.error({
                    message: 'Error',
                    description: 'Error occurred. Contact an administrator.!'
                });
            }
        });
    }

    render() {
        const top_section_boolean = !isNaN(this.props.toc_location) || (this.props.toc_location.split('_').length == 2 && this.props.toc_location.split('_')[1] == 0);
        const pages_component =  top_section_boolean ? <Form.Item>
            {this.props.form.getFieldDecorator(`pages`, {
                rules: [{required: true, message: 'Number of pages is required.'}]
            })(<InputNumber placeholder="Pages" key={'pages'} min={1} onChange={(value) => this.setState({...this.state, prev_pages: value })}/>
            )}</Form.Item>: '';

        return (
                <SectionWrapper>
                    <Form onSubmit={this.handleSubmit}>
                    {pages_component}
                    <DragDropContext onDragEnd={(drag) => {this.handleDragAndDrop(drag)}}>
                        <Droppable droppableId={1}>
                            {provided => (
                                <SectionFormItems {...provided.droppableProps} handleCollapsedSections={this.props.handleCollapsedSections}
                                innerRef={provided.innerRef} form={this.props.form} add_item={this.add} setPrevImage={this.setPrevImage}
                                setFileList={this.setFileList} file_list={this.state.fileList} emptyFileList={this.emptyFileList}
                                remove_item={this.showSectionDeleteConfirm} elements={initial_elements} prev_image={this.state.prev_image} collapsed={this.props.collapsed}
                                removeImage={this.props.removeImage} section_id={this.props.section_id} addMidItem={this.addMidItem}
                                tables={this.props.tables} images={this.props.images} changeUploadProgress={this.changeUploadProgress} upload_in_progress={this.state.upload_in_progress}
                                setTableIndex={this.props.setTableIndex} setImageIndex={this.props.setImageIndex}
                                input_refs={input_refs} create_table={this.createTable}
                                data={data} columns={columns} 
                                previous_image={this.props.previous_image}
                                setState={this.props.setState}
                                />
                            )}
                        </Droppable>
                    </DragDropContext>
                    </Form>
                    <div className="section-actions">
                        <Tooltip title="Add base item" placement="left">
                            <Icon type="plus-circle" className="section-action-icon"
                            onClick={(e) => this.add(e)}/>
                        </Tooltip>
                        <Tooltip title="Add table" placement="left">
                            <Icon type="table" className="section-action-icon" 
                            onClick={(e) => this.add(e, 'Table')}/>
                        </Tooltip>
                        <Tooltip title="Add image" placement="left">
                            <Icon type="picture" className="section-action-icon" 
                            onClick={(e) => this.add(e, 'Image')}/>
                        </Tooltip>
                        <Tooltip title="Save" placement="left">
                            <Icon type="save" className="section-action-icon" 
                            onClick={(e) => {this.handleSubmit(e, false)}}/>
                        </Tooltip>
                        <Tooltip title="Next" placement="left" htmlType="submit">
                            <Icon type="arrow-right" className="section-action-icon" 
                            onClick={(e) => {this.handleSubmit(e, true)}}/>
                        </Tooltip>
                    </div>
                    <div ref={(el) => this.scrollRef = el}></div>
                </SectionWrapper>
        );
    }
}

export default withRouter(SectionContainer);