import styled from "styled-components";

export const TabContainerWrapper = styled.div`
  width: 1200px;
  margin: 0 auto 16px auto;
  margin-top: 50px;
  
  .ant-tabs-bar {
    width: 415px;
    margin: 0 auto 16px auto;
  }

  .ant-select-enabled {
    width: 415px;
    margin: 0 auto 16px auto;
  }
`;
