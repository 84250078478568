import React from 'react';
import { Select, notification } from 'antd';
import { fetchReports, fetchPreview } from './PreviewActions';
import { EditContainerWrapper } from './PreviewContainerStyle';

const Option = Select.Option;

class PreviewReport extends React.Component {

    state = {
        data: [],
        options: [],
        value: undefined,
    }

    componentDidMount = async () => {
        try {
            let response = await fetchReports();
            this.setState({...this.state, data: response.data, options: response.data});
        } catch (error) {
            console.log(error);
            notification['error']({
                message: 'Error',
                description: 'Error fetching reports',
            });
        }
        
    }
    
    handleChange = async (value, option) => {
        let report = this.findReport(value);
        let html_string = await fetchPreview(report.id, report.version);
        let new_window = null;
        if(html_string.data && html_string.data.htmlString) {
            new_window = window.open('');
            new_window.document.body.innerHTML = html_string.data.htmlString;
            new_window.document.title = option.props.children;
        } else {
            notification.error({
                message: 'Error',
                description: 'No content to preview!'
            });
        }
    } 

    findReport = (id) => {
        for(let elem of this.state.data) {
            if((elem.id + '/' + elem.version)== id) {
                return elem;
            }
        }
        return null;
    }

    filterReports = (query) => {
        if(query) {
            this.setState({
                ...this.state,
                options: this.state.data.filter(elem => {
                        return elem.title.toLowerCase().indexOf(query.toLowerCase()) != -1;
                    })
            })
        } else {
            this.setState({
                ...this.state,
                options: this.state.data
            })
        }
    }

    render() {
        const options = this.state.options && this.state.options.length ? this.state.options.map(elem => <Option key={elem.id + '/' + elem.version}>{elem.title + ' v' + elem.version}</Option>) : [];

        return (
            <div>
            <EditContainerWrapper>
                <Select
                showSearch
                value={this.state.value ? this.state.value : undefined}
                placeholder= 'Enter a search query'
                defaultActiveFirstOption={false}
                allowClear
                onSearch={this.filterReports}
                showArrow={false}
                filterOption={false}
                onChange={this.handleChange}
                notFoundContent={null}
                >
                {options}
                </Select>
            </EditContainerWrapper>
            </div>
        )
    }
}

export default PreviewReport;