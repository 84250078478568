import axios from "axios";
import config from "../../../../../config/config";

export const PDFUpload = (form_data, report_id, report_version, clientType, forecast_period) => {
  const endpoint = clientType === "S&P" ? config.api.sandp_upload_pdf : config.api.pdf_upload;
  
  const url = `${config.api.base_url}${endpoint}?id=${report_id}&version=${report_version}&forecast_period=${forecast_period}`;

  return axios.post(url, form_data, {
    headers: { authorization: localStorage.getItem("token") }
  });
};
