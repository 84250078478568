import styled from 'styled-components';

export const TocWrapper = styled.div`
    width: 20%;
    height: 100%;
    overflow: auto;
    border-right: 2px solid #eee;
    .ant-menu-inline {
        border-right: none;
    }
    .toc-edit-icon {
        margin-left: 5px;
        z-index: 200;
    }
`