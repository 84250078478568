import React from 'react';
import { Button, Icon, Dropdown, Menu } from 'antd';
import { ContentHeaderWrapper } from './ContentHeader.style';

const ContentHeader = ({title, updateSubmitForm, toc_location, handleCollapse}) => {

    const handlePreview = (parent) => {
        updateSubmitForm(true, parent);
    }

    const handleFold = (value) => {
        handleCollapse(value);
    }

    let toc_arr = toc_location.split('_');

    const menu = 
    <Menu>
        <Menu.Item onClick={() => handlePreview(false)}>
            Current Section
        </Menu.Item>
        <Menu.Item onClick={() => handlePreview(true)}> 
            Subsections 
        </Menu.Item>
    </Menu>;

    const fold_menu = 
    <Menu>
        <Menu.Item onClick={() => handleFold(true)}>
            <Icon type="menu-fold" /> Fold All
        </Menu.Item>
        <Menu.Item onClick={() => handleFold(false)}> 
            <Icon type="menu-unfold" /> Unfold All 
        </Menu.Item>
    </Menu>;

    const dropdown = !isNaN(toc_location) || (toc_arr.length == 2 && toc_arr[1] == 0) 
    ? 
    <Dropdown overlay={menu} trigger={['click']}>
        <Button type="primary"><Icon type="eye" theme="filled" /> Preview </Button>
    </Dropdown>
    :
    <Button type="primary" onClick={() => handlePreview(false)}><Icon type="eye" theme="filled" /> Preview </Button>
    
    const fold = 
    <Dropdown overlay={fold_menu} trigger={['click']}>
        <Button><Icon type="bars" /> Fold/Unfold All </Button>
    </Dropdown>

    return (
        <ContentHeaderWrapper>
            <h2>{title}</h2>
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
                {dropdown}
                {fold}
            </div>
        </ContentHeaderWrapper>
    );
};

export default ContentHeader;