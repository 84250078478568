import axios from "axios";
import config from "../../../../../config/config";

export const addMeta = (form_data) => {
  return axios.post(config.api.base_url + config.api.add_meta, form_data, {
    headers: { authorization: localStorage.getItem("token") },
  });
};

export const addFactsetMeta = (form_data) => {
  return axios.post(
    config.api.base_url + config.api.add_factset_meta,
    form_data,
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const addSandPMeta = (form_data) => {
  return axios.post(
    config.api.base_url + config.api.sandp_add_meta,
    form_data,
    { headers: { authorization: localStorage.getItem("token") } }
  );
};