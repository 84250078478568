import axios from 'axios';
import config from '../../../../config/config';

export const fetchReports = (search_query) => {
    let url = window.location.origin.includes('mordorintelligence') ? config.api.base_url_prod : window.location.origin.includes('mordorlabs') ? config.api.base_url_test : config.api.base_url_dev 
     return axios.get(url.substring(0, url.length - 4) + config.api.search + search_query,{headers: {authorization: localStorage.getItem('token')}}); 
 }

export const publishReport = (report_id, version) => {
    return axios.get(config.api.base_url + config.api.publish_report + '?id=' + report_id + '&version=' + version, {headers: {authorization: localStorage.getItem('token')}});
}

export const deleteReport = (report_id, version) => {
    return axios.delete(config.api.base_url + config.api.delete_report + '?id=' + report_id + '&version=' + version, {headers: {authorization: localStorage.getItem('token')}});
}

export const uploadPDF = (file, report_id, version, callback) => {
    let data = new FormData();
    data.append('attachment', file.file);
    axios.post(config.api.base_url + config.api.upload_pdf + '?id=' + report_id
    + '&version=' + version, data, {headers: {authorization: localStorage.getItem('token')}})
    .then(response => {
        if(response.status === 200) {
            callback(null);
        }
        else
            callback(new Error('Non 2XX status reached on PDF upload'));
    }).catch(error => {
        callback(error);
    })
}

export const uploadTOC = (file, report_id, version, forecast_period, callback) => {
    let data = new FormData();
    data.append('attachment', file.file);
    axios.post(config.api.base_url + config.api.toc_upload + '?id=' + report_id
    + '&version=' + version + '&forecast_period=' + forecast_period, data, {headers: {authorization: localStorage.getItem('token')}})
    .then(response => {
        if(response.status === 200) {
            callback(null);
        }
        else
            callback(new Error('Non 2XX status reached on CSV upload'));
    }).catch(error => {
        callback(error);
    })
}

export const getReportFactsetPublished = async (report_id, forecast_period) => {
    try {
        const res = await axios.get(config.api.base_url + config.api.get_report_factset + '?id=' + report_id + '&forecast_period=' + forecast_period, { headers: { authorization: localStorage.getItem('token') } });
        return res;
    } catch (err) {
        // console.error("Error response:");
        // console.error(err.response.data);    
        // console.error(err.response.status);  
        // console.error(err.response.headers);
        return err.response;
    }
}

export const getReportSandPPublished = async (report_id, forecast_period) => {
    try {
        const res = await axios.get(config.api.base_url + config.api.sandp_ispublished + '?id=' + report_id + '&forecast_period=' + forecast_period, { headers: { authorization: localStorage.getItem('token') } });
        return res;
    } catch (err) {
        // console.error("Error response:");
        // console.error(err.response.data);    
        // console.error(err.response.status);  
        // console.error(err.response.headers);
        return err.response;
    }
}

export const getReportNonFactsetPublished = async (report_id, forecast_period) => {
    try {
        const res = await axios.get(config.api.base_url + config.api.get_report_non_factset_published + '?id=' + report_id + '&forecast_period=' + forecast_period, { headers: { authorization: localStorage.getItem('token') } });
        return res;
    } catch (err) {
        // console.error("Error response:");
        // console.error(err.response.data);    
        // console.error(err.response.status);  
        // console.error(err.response.headers);
        return err.response;
    }
}