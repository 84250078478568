import React, { useEffect, useState } from "react";
import { Button, Form, Select, Typography, DatePicker, Input, notification } from "antd";
import { FactsetWrapper } from "./FactsetStyle";
import config from "../../../../../config/config";

const { Option } = Select;
const { Text } = Typography;

const Factset = (props) => {
  const form = props.form;
  const category_name = props.current_report.category_name;

  const [display_person_id, setDisplay_person_id] = useState("");

  const personID_with_category_mapping = config.personID_with_category_mapping;
  const personID_value_mapping = config.personID_value_mapping;
  const country_name_with_country_codes =
    config.country_name_with_country_codes;

  let filtered_person_id_value;

  const handleSubmit = (e) => {
    e.preventDefault();
    let hasErrors = false;

    !hasErrors &&
      form.validateFields((err, values) => {
        if (!err) {
          let form_details = {
            personId: values.personId,
            givenName: values.givenName,
            displayName: values.displayName,
            countryCodes: country_name_with_country_codes
              .filter((item) =>
                values.countryCodesAndNames.includes(item.countryName)
              )
              .map((i) => i.countryCode),
            publishDate: values.publishDate,
          };

          form_details["geography"] = {
            [values["geography"]]: values.geography2,
          };

          let sanatized_forecast_period = values.forecast_period.replace(/\s/g, '');
          const pattern = /^(\d{4}|[a-z]{4})-(\d{4}|[a-z]{4})$/;
          if (pattern.test(sanatized_forecast_period)) {
            form_details["forecast_period"] = sanatized_forecast_period;
            props.handleFactsetMetaAddition(form_details);
          } else {
            notification.error({
              message: "Error",
              description: (
                <p>
                  Invalid forecast period format! <br />
                  <strong>Please use format: XXXX-XXXX</strong> <br />
                  (ex: 2024-2027)
                </p>
              )
            });
          }
        }
      });
  };

  const handleGeographyChange = (value) => {
    form.setFieldsValue({
      geography2: undefined,
    });
    props.handleGeographyChange(value);
  };

  const updatePersonIdWithCategoryName = () => {
    for (let person_id_value of Object.keys(personID_with_category_mapping)) {
      if (
        personID_with_category_mapping[person_id_value].includes(category_name)
      ) {
        filtered_person_id_value = person_id_value;
      }
    }

    for (let person_id of Object.keys(personID_value_mapping)) {
      if (
        personID_value_mapping[person_id].includes(filtered_person_id_value)
      ) {
        setDisplay_person_id(person_id);
      }
    }
  };

  useEffect(() => {
    updatePersonIdWithCategoryName();
  }, []);

  const handleKeyPress = e => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  return (
    <FactsetWrapper>
      <Form onSubmit={handleSubmit}>
        <div style={{ display: "flex" }}>
          <Form.Item>
            <Text> Geography : </Text>
          </Form.Item>

          <Form.Item style={{ marginLeft: "50px" }}>
            {form.getFieldDecorator("geography", {
              rules: [
                {
                  required: true,
                  message: "Please enter a geography tag!",
                  whitespace: true,
                },
              ],
            })(
              <Select
                showSearch
                placeholder="Geography"
                onChange={(value) => {
                  handleGeographyChange(value);
                }}
              >
                <Option key="global">Global</Option>
                <Option key="factset_region">Region</Option>
                <Option key="factset_country">Country</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item style={{ marginLeft: "25px" }}>
            {form.getFieldDecorator("geography2", {
              rules: [
                {
                  required: true,
                  message: "Please enter a country/region/global!",
                  whitespace: true,
                },
              ],
            })(
              <Select
                showSearch
                placeholder="Please enter a country/region/global"
              >
                {props.chosen_options}
              </Select>
            )}
          </Form.Item>
        </div>

        <div className="factset_form_container">
          <div className="factset_field">
            <Form.Item>
              <Text> Person Id :</Text>
            </Form.Item>

            <div style={{ marginLeft: "50px" }}>
              <Form.Item>
                {form.getFieldDecorator("personId", {
                  initialValue: display_person_id,
                  rules: [
                    { required: true, message: "Please input Person Id!" },
                  ],
                })(
                  <Select showSearch placeholder="Enter Person Id">
                    <Option key={" "} value="ID111111">
                      ID111111
                    </Option>
                    <Option key={" "} value="D111112">
                      ID111112
                    </Option>
                    <Option key={" "} value="ID111113">
                      ID111113
                    </Option>
                    <Option key={" "} value="ID111114">
                      ID111114
                    </Option>
                    <Option key={" "} value="ID111115">
                      ID111115
                    </Option>
                    <Option key={" "} value="ID111116">
                      ID111116
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </div>
          </div>

          <div className="factset_field">
            <Form.Item>
              <Text> Given Name :</Text>
            </Form.Item>
            <div className="factset_field_value">
              <Form.Item>
                {form.getFieldDecorator("givenName", {
                  initialValue: "Mordor Intelligence",
                  rules: [
                    {
                      required: true,
                      message: "Please input your Given Name!",
                    },
                  ],
                })(
                  <Select placeholder="Enter Given Name">
                    <Option key="Mordor Intelligence" disabled>
                      Mordor Intelligence
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </div>
          </div>

          <div className="factset_field">
            <Form.Item>
              <Text> Display Name :</Text>
            </Form.Item>
            <div className="factset_field_value">
              <Form.Item>
                {form.getFieldDecorator("displayName", {
                  initialValue: "Mordor Intelligence Research Team",
                  rules: [
                    { required: true, message: "Please input Person Id!" },
                  ],
                })(
                  <Select placeholder="Enter Display Name">
                    <Option key="Mordor Intelligence Research Team" disabled>
                      Mordor Intelligence Research Team
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </div>
          </div>

          <div className="factset_field">
            <Form.Item>
              <Text> Country Codes :</Text>
            </Form.Item>
            <div className="factset_field_value">
              <Form.Item>
                {form.getFieldDecorator("countryCodesAndNames", {
                  rules: [
                    { required: true, message: "Please input Country Codes!" },
                  ],
                })(
                  <Select
                    mode="multiple"
                    placeholder="Please select Columns"
                    maxTagCount={5}
                  >
                    {country_name_with_country_codes.map((option) => (
                      <Option
                        key={option.countryCode}
                        value={option.countryName}
                      >
                        {option.countryName}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </div>
          </div>

          <div className="factset_field_publish">
            <Form.Item>
              <Text>Publish Date :</Text>
            </Form.Item>
            <div className="factset_field_value">
              <Form.Item>
                {form.getFieldDecorator("publishDate", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Publish Date!"
                    },
                  ],
                })(
                  <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                )}
              </Form.Item>
            </div>
          </div>

          <div className="factset_field_publish">
            <Form.Item>
              <Text>Forecast Period:</Text>
            </Form.Item>
            <div className="factset_field_value">
              <Form.Item>
                {form.getFieldDecorator("forecast_period", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Forecast Period!"
                    },
                  ],
                })(
                  <Input 
                  placeholder="2024-2027" 
                  onKeyPress={handleKeyPress}/>
                )}
              </Form.Item>
            </div>
          </div>
        </div>

        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </Form>
    </FactsetWrapper>
  );
};

export default Form.create()(Factset);