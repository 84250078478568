import styled from "styled-components";

export const CatalogueContainerWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;

  .client_dropdown {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
  }
`;

export const TableContainerWrapper = styled.div`
  .ant-pagination-options {
    margin-top: 15px;
    width: 60px;
  }
  .ant-pagination-options-size-changer {
    width: 60px;
  }
  .seach-icon:hover {
    color: blue;
  }
`;
