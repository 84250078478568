import axios from "axios";
import config from "../../../../config/config";

export const fetchReports = () => {
  return axios.get(
    config.api.base_url + config.api.report_list + "?status=PUBLISHED",
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const fetchFactsetReports = () => {
  return axios.get(
    config.api.base_url + config.api.factset_catalogue + "?status=PUBLISHED",
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const fetchSandPReports = () => {
  return axios.get(
    config.api.base_url + config.api.sandp_get_reports + "?status=PUBLISHED",
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const exportReports = (selectedReports) => {
  return axios.post(
    config.api.base_url + config.api.export_html,
    { reports: selectedReports },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const exportxmlReports = (selectedReports) => {
  return axios.post(
    config.api.base_url + config.api.export_xml,
    { reports: selectedReports },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const exportFactsetReports = (selectedReports) => {
  return axios.post(
    config.api.base_url + config.api.export_factset,
    { reports: selectedReports },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const exportFactsetReportsv24 = (selectedReports) => {
  return axios.post(
    config.api.base_url + config.api.export_factsetv24,
    { reports: selectedReports },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const exportSandPReports = (selectedReports) => {
  return axios.post(
    config.api.base_url + config.api.export_sandp,
    { reports: selectedReports },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const markAsSent = (selectedReports, batch) => {
  return axios.post(
    config.api.base_url + config.api.mark_as_sent,
    { sent_reports: selectedReports, batch: batch },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const markAsSentFactset = (selectedReports, batch) => {
  return axios.post(
    config.api.base_url + config.api.mark_as_sent_factset,
    { sent_reports: selectedReports, batch: batch },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const markAsSentSandP = (selectedReports, batch) => {
  return axios.post(
    config.api.base_url + config.api.mark_as_sent_sandp,
    { sent_reports: selectedReports, batch: batch },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const unmarkAsSent = (selectedReports) => {
  return axios.post(
    config.api.base_url + config.api.mark_as_sent + "?action=unsent",
    { sent_reports: selectedReports },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const unmarkAsSentFactset = (selectedReports) => {
  return axios.post(
    config.api.base_url + config.api.mark_as_sent_factset + "?action=unsent",
    { sent_reports: selectedReports },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const unmarkAsSentSandP = (selectedReports) => {
  return axios.post(
    config.api.base_url + config.api.mark_as_sent_sandp + "?action=unsent",
    { sent_reports: selectedReports },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const unpublishReports = (selectedReports) => {
  return axios.post(
    config.api.base_url + config.api.unpublish,
    { reports: selectedReports },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const unpublishReportsFactset = (selectedReports) => {
  return axios.post(
    config.api.base_url + config.api.unpublish_factset,
    { reports: selectedReports },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const unpublishReportsSandP = (selectedReports) => {
  return axios.post(
    config.api.base_url + config.api.unpublish_sandp,
    { reports: selectedReports },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const deleteFactset = (selectedReports) => {
  return axios.post(
    config.api.base_url + config.api.delete_factset_report,
    { reports: selectedReports },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const deleteSandP = (selectedReports) => {
  return axios.post(
    config.api.base_url + config.api.delete_sandp_report,
    { reports: selectedReports },
    { headers: { authorization: localStorage.getItem("token") } }
  );
};

export const fetchBatchesFilter = (type) => {
  return axios.get(
    config.api.base_url + config.api.batch_filters + `?type=${type}`, // type can be "non-factset", "factset", "sandp"
    { headers: { authorization: localStorage.getItem("token") } }
  );
};